
// Icon
import { BsFillFileEarmarkPlusFill, BsPencilSquare, BsTrash } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";

export default function CardArticulo({ id, articuloL, titulo, categorias, actionEdit, actionDelete, actionPreview, actionClone, categoriasList }) {
let a =[]
categorias.forEach(element => {
    categoriasList.filter((categoria) => {
        if (categoria.Name === element.name) {
            a.push(categoria.CategoriasID)       
        }
    });
});
    return (
        <div className="card">
            <div className="card-width-40 d-flex">
                <div className="container-id mr-2">
                    <p>{ id }</p>
                </div>
                <div className="container-title">
                    <p>{ titulo }</p>
                </div>
            </div>
            <div className='container-categoria'>
                {categorias.map((categoria,index) => {
                    return <span key={index} className='categoria-item' style={{ backgroundColor: categoria.color, color: categoria.textColor }} >{ categoria.name }</span>;
                })}
            </div>
            <div className='container-action gap-15'>
                {/* clonar articulo */}
                <button className="btn btn-sm btn-clone" onClick={() => actionClone(articuloL,a)}>
                    <BsFillFileEarmarkPlusFill className='white' size='1rem' />
                </button>
                
                <button className="btn btn-sm btn-edit" onClick={() => actionEdit()}>
                    <BsPencilSquare className='white' size='1rem' />
                </button>
                <button className="btn btn-sm btn-delete" onClick={() => actionDelete()}>
                    <BsTrash className='white' size='1rem' />
                </button>
                <button className="btn btn-sm btn-info" onClick={() => actionPreview()}>
                    <AiOutlineEye className='white' size='1rem' />
                </button>
            </div>
        </div>
    );
}