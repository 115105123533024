
// Componets
import AdminHeader from "../../components/AdminHeader";

// Notificaciones
import { ToastContainer, toast } from 'react-toastify';
import { toastConfig } from "../../helpers/notificaciones";

// React
import { useEffect, useState } from "react";

// APIs
import { createRequisito, getRequisitos, updateRequisito } from "../api/Requisitos";

export const updateSuccess = () => toast.success('Requisito actualizado con éxito', toastConfig);
export const updateErr = () => toast.error('Error al actualizar requisito', toastConfig);


export default function Requisitos() {

    const [requisitos, setRequisitos] = useState([]);

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
        obtenerRequisitos();
    }, [])

    async function obtenerRequisitos() {
        // await createRequisito('', 'Safari', '12', '[ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ]')
        setRequisitos(await getRequisitos())
    }

    async function handleRequisito(requisito, value) {
        const { RequisitosID, SO, Navegador, Versiones } = requisito;
        await updateRequisito(SO, Navegador, value, Versiones, RequisitosID ) ? updateSuccess() : updateErr();
        obtenerRequisitos();
    }

    return (
        <>
            <AdminHeader />
            <ToastContainer />

            <div className='container-requirements'>
                <div className='box mt-4'>
                    <h3>Versión mínima para sistemas operativos</h3>

                    { requisitos.map( (requisito, index) => {
                        return (
                            requisito.SO != '' ?
                            <div key={index} className='mt-4 content'>
                                <p className='title'>{ requisito.SO }</p>
                                <ul className='tags'>
                                    { JSON.parse(requisito.Versiones).map( (version, index) => {
                                        return  <li key={ index } className={ requisito.Version_Minima == version ? 'active' : '' } onClick={ () => handleRequisito(requisito, version) }>{ version }</li>;
                                    }) }
                                </ul>
                            </div> : <></>
                        );
                    }) }
                </div>
                <div className='box mb-4'>
                    <h3>Versión mínima para sistemas operativos</h3>

                    { requisitos.map( (requisito, index) => {
                        return (
                            requisito.Navegador != '' ?
                            <div key={index} className='mt-4 content'>
                                <p className='title'>{ requisito.Navegador }</p>
                                <ul className='tags'>
                                    { JSON.parse(requisito.Versiones).map( (version, index) => {
                                        return <li key={ index } className={ requisito.Version_Minima == version ? 'active' : '' } onClick={ () => handleRequisito(requisito, version) }>{ version }</li>;
                                    }) }
                                </ul>
                            </div> : <></>
                        );
                    }) }
                </div>
            </div>
        </>
    );
}