// Icon
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";

// Components
import ModalHeader from "./ModalHeader";

export default function ModalClone({
  isOpen,
  setIsOpen,
  text,
  cloneAction,
  articleToClone,
}) {
  return (
    <div className={`modal ${isOpen ? "active" : ""}`}>
      <div className="modal-container" onClick={() => setIsOpen(false)}></div>
      <div className="modal-content">
        <ModalHeader setIsOpen={setIsOpen} />

        <div className="modal-body">
          <div>
            <div className="d-flex justify-content-center mb-2">
              <BsFillFileEarmarkPlusFill
                size="1.5rem"
                className="color-orange"
              />
            </div>
            <h3 className="text-center">{text}</h3>
            <div className="d-flex justify-content-around mt-5">
              {/* Botón para clonar el artículo */}
              <button
                className="btn"
                onClick={() => {
                  // Llamar a la función actionClone con el objeto del artículo a clonar
                  cloneAction(articleToClone);
                  setIsOpen(false);
                }}
              >
                Clonar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
