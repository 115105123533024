import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { getArticulos } from "../../admin/api/Articulos";
import { phoneDevice } from "../../helpers/device";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../layout/Main";
import Modal from "../../components/modal/Modal";


export default function Suggestions() {

    const recomendaciones = 'Recomendaciones';
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false)

    const [tags, setTags] = useState([]);
    const [articulo, setArticulo] = useState({});
    const [articulos, setArticulos] = useState([]);

    useEffect(() => {
        window.onpopstate = e => {
            if (isOpen) {
                window.history.forward();
                setIsOpen(false);   
            }
        };
    }, [isOpen])

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
        
        obtenerArticulos();
    }, [])

    useEffect(() => {
        document.querySelectorAll('img[alt="emoji"]').forEach(el => { el.classList.add('img-inline') })
    }, [isOpen])
    
    async function obtenerArticulos() {
        let device = phoneDevice();
        let articulos = await getArticulos();        

        device.os = device.os.replaceAll("_", ".");

        switch (true) {
            case device.os.toUpperCase().includes('ANDROID'):
                setArticulos(articulos.filter(articulo => { return articulo.categorias.includes(2) && articulo.categorias.includes(16) }));
                setTags([{
                    Name: recomendaciones.toUpperCase(),
                    Color: 'rgb(112, 212, 75)',
                    Text_Color: 'rgb(255, 255, 255)',
                },
                {
                    Name: 'ANDROID',
                    Color: 'rgb(59, 212, 128)',
                    Text_Color: 'rgb(255, 255, 255)',
                }]);
                break;
            case device.os.toUpperCase().includes('IOS'):
                setArticulos(articulos.filter(articulo => { return articulo.categorias.includes(2) && articulo.categorias.includes(18) }));
                setTags([{
                    Name: recomendaciones.toUpperCase(),
                    Color: 'rgb(112, 212, 75)',
                    Text_Color: 'rgb(255, 255, 255)',
                },
                {
                    Name: 'IOS',
                    Color: 'rgb(7, 7, 7)',
                    Text_Color: 'rgb(255, 255, 255)',
                }]);
                break;
            default:
                setArticulos(articulos.filter(articulo => { return articulo.categorias.includes(2) }));
                setTags([{
                    Name: recomendaciones.toUpperCase(),
                    Color: 'rgb(112, 212, 75)',
                    Text_Color: 'rgb(255, 255, 255)',
                }]);
                break;
        }
    }

    function redirect(url) {
        navigate(url);
    }
    
    function handleModal(articulo, open) {
        setArticulo(articulo);
        setIsOpen(open);
    }
    
    return (
        <Main>
            <Header section='suggestions' notLogo={true} title='Recomendaciones Generales' />
            <div className="container-phone">
                <div className="problems">

                    <div className="content-tags">
                        <div className="tags">
                            {tags.map((tag, index) => {
                                return <span style={{ background: tag.Color, color: tag.Text_Color }} className="categories">{ tag.Name }</span>;
                            })}
                        </div>
                    </div>

                    <ul className="list-of-problems size-2">
                        {articulos.map((articulo, index) => {
                            return (
                                <li key={index}>
                                    <div className='content-tags cursor-pointer' onClick={ () => handleModal(articulo, true) }>
                                        <span className="title">{ articulo.Title }</span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <div className='modal-body'>
                    <h2 className='mb-4'>{ articulo.Title }</h2>
                    <div className="modal-editor">
                        {articulo.Message != undefined ? parse(articulo.Message) : ''}
                    </div>
                </div>
            </Modal>
        </Main>
    );
}