import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getArticuloById} from "../../admin/api/Articulos";
import {getCategorias} from "../../admin/api/Categorias";

// Router
import {Link} from "react-router-dom";

// Render
import parse from "html-react-parser";
import Header from "../../components/Header";
import ModalHeader from "../../components/modal/ModalHeader";

export default function Articulo() {
    const [articulo, setArticulo] = useState({});
    const [categorias, setCategorias] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
        obtenerArticulo();
        obtenerCategorias();
    }, []);

    async function obtenerArticulo() {
        const articulo = await getArticuloById(id);

        if (articulo.code == 'ERR_NETWORK') {
            window.history.back();
        }

        let msg = articulo.Message.split("/", 4);
        articulo.msj = msg.length < 4 ? msg.toString().replaceAll(",", "/") : `${msg.toString().replaceAll(",", "/")}/>`;

        setArticulo(articulo);
    }

    async function obtenerCategorias() {
        setCategorias(await getCategorias());
        await new Promise((resolve) => setTimeout(resolve, 150));
        setIsLoading(false);
    }

    return (
        <>
            <Header url={`/admin/articulos`} />

            <div className="container-phone">
                <div className="suggestions">
                    <div className="wrapper">
                        <h1>{articulo.Title}</h1>

                        {isLoading ? (
                            <></>
                        ) : (
                            <div className="articulos-container">
                                <div className="result mt-3">
                                    <p className="title mb-1">{articulo.Title}</p>
                                    <div className="body">
                                        {parse(articulo.msj)}
                                        <span className="read-more ml-1" onClick={() => setIsOpen(true)}>
                                            Leer más
                                        </span>
                                    </div>

                                    <div className="tags mt-4">
                                        {categorias
                                            .filter((categoria) => articulo.categorias.includes(categoria.CategoriasID))
                                            .map((categoria) => {
                                                return <a>{categoria.Name}</a>;
                                            })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className={`modal ${isOpen ? "active" : ""}`}>
                <div className="modal-container" onClick={() => setIsOpen(false)}></div>
                {isLoading ? (
                    <></>
                ) : (
                    <div className="modal-content">
                        <ModalHeader setIsOpen={setIsOpen} />

                        <div className="modal-body">
                            <h2 className="mb-4">{articulo.Title}</h2>
                            <div>{parse(articulo.Message)}</div>
                        </div>

                        {articulo.Link !== "" && articulo.Link !== "None" && articulo.Link !== null ? (
                            <div className="modal-footer">
                                <p>
                                    ¿No pudiste resolver tu problema? pulsa{" "}
                                    {isNaN(articulo.Link) ? (
                                        <a className="link" target="_blank" href={articulo.Link}>
                                            aquí
                                        </a>
                                    ) : (
                                        <Link to={`/guia/${articulo.Link}`} className="link">
                                            aquí
                                        </Link>
                                    )}{" "}
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
