

// React
import { useEffect, useState } from "react";

// Notificaciones
import { ToastContainer, toast } from 'react-toastify';
import { toastConfig } from "../../helpers/notificaciones";

// Router
import { Link, useParams } from "react-router-dom";

// Componets
import AdminHeader from "../../components/AdminHeader";
import NavMain from "../../components/NavMain";
import ModalHeader from "../../components/modal/ModalHeader";
import CardPasos from "../../components/CardPasos";
import ModalDelete from "../../components/modal/ModalDelete";

// Icon
import { AiOutlineEye } from "react-icons/ai";

// APIs
import { createPaso, deletePaso, getPasoById, getPasos, updatePaso } from "../api/Pasos";

export const createSuccess = () => toast.success('Paso creado con éxito', toastConfig);
export const createErr = () => toast.error('Error al crear paso', toastConfig);
export const updateSuccess = () => toast.success('Paso actualizado con éxito', toastConfig);
export const updateErr = () => toast.error('Error al actualizar paso', toastConfig);
export const deleteSuccess = () => toast.success('Articulo eliminado con éxito', toastConfig);
export const deleteErr = () => toast.error('Error al eliminar paso', toastConfig);

export default function GuiaById() {
    
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenMD, setIsOpenMD] = useState(false);
    const [isActive, setIsActive] = useState(undefined);
    const [pasos, setPasos] = useState([]);
    const [filterPasos, setFilterPasos] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [pasoId, setPasoId] = useState(null);

    const [titleMessaje, setTitleMessaje] = useState('');
    const [messaje, setMessaje] = useState('');
    const [titleQuestion, setTitleQuestion] = useState('');
    const [question, setQuestion] = useState('');
    const [linkAfirmativo, setLinkAfirmativo] = useState('');
    const [labelRight, setLabelRight] = useState('');
    const [linkNegativo, setLinkNegativo] = useState('');
    const [labelLeft, setLabelLeft] = useState('');
    const [linkReturn, setLinkReturn] = useState('');

    const { id } = useParams();

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
        obtenerPasos();
    }, [])

    async function obtenerPasos() {
        setPasos(await getPasos(id));
    }

    async function sentForm (e, pasoId) {
        e.preventDefault();

        const { title_messaje, messaje, title_question, question } = e.target.elements;

        if ((title_messaje.value !== '' && messaje.value !== '') || (title_question.value !== '' && question.value !== '')) {

            if (pasoId != undefined) {
                await updatePaso(id, title_messaje.value, messaje.value, title_question.value, question.value, linkAfirmativo, linkNegativo, linkReturn, labelRight, labelLeft , pasoId) ? updateSuccess() : updateErr();
            } else { //CREATE
                await createPaso(id, title_messaje.value, messaje.value, title_question.value, question.value, null, null, linkReturn, labelRight, labelLeft) ? createSuccess() : createErr();
            }

            obtenerPasos();
            setIsOpen(false);
        }
    }

    async function pasoDelete () {
        await deletePaso(pasoId) ? deleteSuccess() : deleteErr();

        obtenerPasos();
        setIsOpenMD(false);
    }

    function showModalCreate() {
        setTitleMessaje('');
        setMessaje('');
        setTitleQuestion('');
        setQuestion('');
        setLinkAfirmativo('');
        setLabelRight('')
        setLinkNegativo('');
        setLabelLeft('')
        setIsActive(undefined);
        setLinkReturn('');
        setFilterPasos([]);

        setIsUpdate(false);
        setIsOpen(true);
    }

    async function showModalUpdate(id) {
        const paso = await getPasoById(id);

        setPasoId(paso.PasosID);
        setTitleMessaje(paso.Title_Message);
        setMessaje(paso.Message);
        setTitleQuestion(paso.Title_Question);
        setQuestion(paso.Question);
        setLinkAfirmativo(paso.Id_Affirmative);
        setLabelRight(paso.Label_Affirmative === 'None' ? '' : paso.Label_Affirmative);
        setLinkNegativo(paso.Id_Negative);
        setLabelLeft(paso.Label_Negative === 'None' ? '' : paso.Label_Negative);

        if (paso.Link_Return == '') {
            setIsActive(undefined);
        } else {
            isNaN(paso.Link_Return) ? setIsActive(false) : setIsActive(true);
        }

        setLinkReturn(paso.Link_Return === 'None' ? '' : paso.Link_Return );
        setFilterPasos( pasos.filter( paso => paso.PasosID > id ) );

        pasos.forEach(ps => {
            if (ps.PasosID == paso.Id_Affirmative) {
                ps.valueAfirmativo = true;
            } else {
                ps.valueAfirmativo = false;
            }

            if (ps.PasosID == paso.Id_Negative) {
                ps.valueNegativo = true;
            } else {
                ps.valueNegativo = false;
            }

            if (ps.PasosID == paso.Link_Return) {
                ps.valueReturn = true;
            } else {
                ps.valueReturn = false;
            }
        });

        setIsUpdate(true);
        setIsOpen(true);
    }

    function showModalDelete(id) {
        setPasoId(id);
        setIsOpenMD(true);
    }

    function handleSwitch(isActive) {
        setIsActive(isActive);
        setLinkReturn('');
    }

    function handleCheckboxA(id, index, state) {
        if (!state) {
            setLinkAfirmativo(id);   
        } else {
            setLinkAfirmativo(null);
        }

        let arr = [...pasos];
        arr.forEach((a, i) => {
            if (index != i) {
                a.valueAfirmativo = false                
            }
        });
        arr[index].valueAfirmativo = !arr[index].valueAfirmativo
        setPasos(arr);
    }

    function handleCheckboxN(id, index, state) {
        if (!state) {
            setLinkNegativo(id);   
        } else {
            setLinkNegativo(null);
        }

        let arr = [...pasos];
        arr.forEach((a, i) => {
            if (index != i) {
                a.valueNegativo = false                
            }
        });
        arr[index].valueNegativo = !arr[index].valueNegativo
        setPasos(arr);
    }

    function handleCheckboxR(id, index, state) {
        if (!state) {
            setLinkReturn(id);   
        } else {
            setLinkReturn(null);
        }

        let arr = [...pasos];
        arr.forEach((a, i) => {
            if (index != i) {
                a.valueReturn = false                
            }
        });
        arr[index].valueReturn = !arr[index].valueReturn
        setPasos(arr);
    }

    return (
        <>
            <AdminHeader />
            <ToastContainer />

            <div className='container-main'>
                <div className="container-lg">
                    <NavMain btnAction={ showModalCreate } btnText='Crear paso' btnTwo={true} btnActionTwo={ () => { window.location.href = `/preview/guia/${id}` }} btnTextTwo='Ver guía' iconTwo={<AiOutlineEye className='mr-2 white' size='1rem' />} classBtnTwo='btn-info' />

                    {pasos.map( (paso, index) => {
                        return <CardPasos key={ index } id={ ++index } cardId={ paso.PasosID } titleMessage={ paso.Title_Message } message={ paso.Message } titleQuestion={ paso.Title_Question } question={ paso.Question } LinkYes={ paso.Id_Affirmative } LinkNo={ paso.Id_Negative } LinkReturn={ paso.Link_Return } actionEdit={ () => showModalUpdate(paso.PasosID) } actionDelete={ () => showModalDelete(paso.PasosID) } LabelYes={ paso.Label_Affirmative } LabelNo={ paso.Label_Negative } />
                    })}
                </div>
            </div>

            {/* Modal Create/Update */}
            <div className={ `modal ${isOpen ? 'active' : '' }` }>
                <div className='modal-container' onClick={() => setIsOpen(false)}></div>
                <div className='modal-content'>
                    <ModalHeader titulo={ isUpdate ? 'Editar paso' : 'Crear paso' } setIsOpen={ setIsOpen } />
                    
                    <div className='modal-body'>
                        <form onSubmit={ e => { isUpdate ? sentForm(e, pasoId) : sentForm(e) }}>

                            <div>
                                <label htmlFor='' className='d-block mb-2' >Título del mensaje</label>
                                <input className='form-input' type="text" name="title_messaje" value={ titleMessaje } onChange={ ({ target }) => setTitleMessaje(target.value) } />
                            </div>

                            <div>
                                <label htmlFor='' className='d-block mb-2' >Mensaje</label>
                                <textarea className='form-input' rows='10' name="messaje" value={ messaje } onChange={ ({ target }) => setMessaje(target.value) } ></textarea>
                            </div>

                            <div>
                                <label htmlFor='' className='d-block mb-2' >Título de la pregunta</label>
                                <input className='form-input' type="text" name="title_question" value={ titleQuestion } onChange={ ({ target }) => setTitleQuestion(target.value) }  />
                            </div>

                            <div>
                                <label htmlFor='' className='d-block mb-2' >Pregunta</label>
                                <input className='form-input' type="text" name="question" value={ question } onChange={ ({ target }) => setQuestion(target.value) }  />
                            </div>

                            { isUpdate && filterPasos.length > 0 ?
                            <div className='mb-4'>
                                <p className='mb-2'>Link afirmativo</p>

                                <div className='form-input-checkbox'>

                                    { pasos.map((paso, index) => {
                                        if ( paso.PasosID !== pasoId ) {
                                            return (
                                                <>
                                                    <input checked={paso.valueAfirmativo} type="radio" name='link_afirmativo' id={`0${ paso.PasosID }`} />
                                                    <label htmlFor={`0${ paso.PasosID }`} onClick={ () => { handleCheckboxA(paso.PasosID, index, paso.valueAfirmativo) }}>{ paso.Title_Message !== '' ? paso.Title_Message : paso.Question }</label>
                                                </>
                                            );                                     
                                        }
                                    }) }

                                </div>
                            </div> : '' }


                            <div>
                                <label htmlFor='' className='d-block mb-2' >Texto del boton afirmativo</label>
                                <input className='form-input' type="text" name="label_right" value={ labelRight } onChange={ ({ target }) => setLabelRight(target.value) }  />
                            </div>

                            { isUpdate && filterPasos.length > 0 ?
                            <div className='mb-4'>
                                <p className='mb-2'>Link negativo</p>

                                <div className='form-input-checkbox'>

                                    { pasos.map((paso, index) => {
                                        if ( paso.PasosID !== pasoId ) {
                                            return (
                                                <>
                                                    <input checked={paso.valueNegativo} type="radio" name='link_negativo' id={`00${ paso.PasosID }`} />
                                                    <label htmlFor={`00${ paso.PasosID }`} onClick={ () => { handleCheckboxN(paso.PasosID, index, paso.valueNegativo) }}>{ paso.Title_Message !== '' ? paso.Title_Message : paso.Question }</label>
                                                </>
                                            );                                            
                                        }
                                    }) }

                                </div>
                            </div>
                            : '' }

                            <div>
                                <label htmlFor='' className='d-block mb-2' >Texto del boton negativo</label>
                                <input className='form-input' type="text" name="label_left" value={ labelLeft } onChange={ ({ target }) => setLabelLeft(target.value) }  />
                            </div>

                            <div className='mb-4' >
                                <p className='mb-2'>Link de retorno</p>
                                <div className='input-switch'>
                                    <button type='button' className={`left ${isActive ? 'active' : ''}`} onClick={ () => handleSwitch(true) }>Paso</button>
                                    <button type='button' className={`right ${!isActive && isActive !== undefined ? 'active' : ''}`} onClick={ () => handleSwitch(false) }>Link externo</button>
                                </div>
                            </div>

                            { isActive ?
                            <div className='mb-4'>
                                <p className='mb-2'>Paso anterior</p>

                                <div className='form-input-checkbox'>
                                    { pasos.map((paso, index) => {
                                        if ( paso.PasosID !== pasoId ) {
                                            return (
                                                <>
                                                    <input checked={paso.valueReturn} type="radio" name='link_return' id={`000${ paso.PasosID }`} />
                                                    <label htmlFor={`000${ paso.PasosID }`} onClick={ () => { handleCheckboxR(paso.PasosID, index, paso.valueReturn) }}>{ paso.Title_Message !== '' ? paso.Title_Message : paso.Question }</label>
                                                </>
                                            );                                            
                                        }
                                    }) }
                                </div>
                            </div> : '' }

                            { !isActive && isActive !== undefined ?
                            <div>
                                <label htmlFor='' className='d-block mb-2' >Link de retorno</label>
                                <input className='form-input' type="text" name="link_return" value={ linkReturn } onChange={ ({ target }) => setLinkReturn(target.value) }  />
                            </div> : ''}


                            <div className='d-flex justify-content-center'>
                                <button type='submit' className='btn btn-success btn-50' >{ isUpdate ? 'Actualizar' : 'Guardar' }</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            {/* Modal delete */}
            <ModalDelete isOpen={ isOpenMD } setIsOpen={ setIsOpenMD } text='¿ Eliminar paso ?' deleteAction={ pasoDelete } />
        </>
    );
}