
// Https
import http from "../../helpers/https";

const url = '/ayuda/categorias';

export async function getCategorias() {
    try {
        const response = await http.get(url);
        return response.data;
    } catch (err) {
        return err;   
    }
}

export async function getCategoriaById(id) {
    try {
        const response = await http.get(url, { params: { id } } );
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function createCategoria(name, color, text_color) {
    try {
        const response = await http.post(url, { name, color, text_color });
        return response.data.id_categoria != undefined ? true : false;
    } catch (err) {
        return false;
    }
}

export async function updateCategoria(name, color, text_color, id) {
    try {
        const response = await http.put(url, { name, color, text_color, id });
        return JSON.stringify(response.data.data) != '{}' ? true : false;
    } catch (err) {
        return false;
    }
}

export async function deleteCategoria(id) {
    try {
        const response = await http.delete(url, { data: { id } });
        return response.data.data.DELETE == 'OK' ? true : false;
    } catch (err) {
        return false;
    }
}