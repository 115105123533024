

// React
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

// Https
import { http } from '../helpers/https';

// Cookies
import Cookies from 'js-cookie';


const AuthContext = createContext(null);

export function AuthProvider({ children }) {

    const [auth, setAuth] = useState({
        id: null,
        logged: Cookies.get('ayuda-login') != undefined ? JSON.parse(Cookies.get('ayuda-login')).logged : false ,
        mail: null,
        name: null
    });

    const login = useCallback(async (correo, pass) => {

        try {
            
            const response = await http.post('/ayuda/login', {
                correo,
                pass,
            });

            const { data } = response;

            if ( data.allow ) {
                
                const value = {
                    id: data.data[0].UsuariosID,
                    email: data.data[0].Email,
                    rol: data.data[0].Rol,
                    logged: !!data.data[0].UsuariosID
                };
          
                setAuth(value);
                Cookies.set('ayuda-login', JSON.stringify(value));

            } else {
                throw new Error('Unauthorized.');
            }

            return true;

        } catch (err) {

            setAuth({
                id: null,
                logged: false,
                mail: null,
                name: null,
            });

            Cookies.remove('ayuda-login');
        }

        return false;

    }, [setAuth]);

    const logout = useCallback(() => {
        setAuth({
          id: null,
          logged: false,
          mail: null,
          name: null,
        });
    
        Cookies.remove('ayuda-login');
      }, [setAuth]);

    useEffect(() => {
        const cookieValue = Cookies.get('ayuda-login');
    
        if (cookieValue) {
          const value = JSON.parse(cookieValue);
          setAuth(value);
        }
    }, []);

    const value = useMemo(() => ({
        auth,
        login,
        logout,
    }), [
        auth, 
        login, 
        logout
    ]);

    return (
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);