

// React
import { useEffect, useState } from "react";

// Notificaciones
import { ToastContainer, toast } from 'react-toastify';
import { toastConfig } from "../../helpers/notificaciones";

// Componets
import AdminHeader from "../../components/AdminHeader";
import NavMain from "../../components/NavMain";
import CardGuias from "../../components/CardGuias";
import ModalHeader from "../../components/modal/ModalHeader";
import ModalDelete from "../../components/modal/ModalDelete";

// Router
import { useNavigate } from "react-router-dom";

// APIs
import { copyGuia, createGuia, deleteGuia, getGuiaById, getGuias, updateGuia } from "../api/Guias";
import { copyCreatePaso, deletePaso, getPasos, updatePaso } from "../api/Pasos";

// Icon
import { FaRegCopy } from "react-icons/fa";

import { deleteErr as pasoDeleteErr } from "./GuiasById";

export const createSuccess = () => toast.success('Guía creada con éxito', toastConfig);

export const createErr = () => toast.error('Error al crear guía', toastConfig);
export const updateSuccess = () => toast.success('Guía actualizada con éxito', toastConfig);
export const updateErr = () => toast.error('Error al actualizar guía', toastConfig);
export const deleteSuccess = () => toast.success('Guía eliminada con éxito', toastConfig);
export const deleteErr = () => toast.error('Error al eliminar guía', toastConfig);

export const duplicateSuccess = () => toast.success('Guía duplicada con éxito', toastConfig);


export default function Guias() {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenMD, setIsOpenMD] = useState(false);
    const [isOpenCopy, setIsOpenCopy] = useState(false);
    const [guias, setGuias] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false)
    const [id, setId] = useState(null);

    const [inputTitle, setInputTitle] = useState('');
    const [inputDescription, setInputDescription] = useState('');

    // COPY
    const [guiaCopy, setGuiaCopy] = useState({})

    const navigate = useNavigate();

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
        obtenerGuias();
    }, [])

    async function obtenerGuias() {
        await new Promise(resolve => setTimeout(resolve, 150));
        setGuias(await getGuias());
    }

    async function sentForm (e, id) {
        e.preventDefault();

        const { title, description } = e.target.elements;

        if ( title.value != '' && description.value != '' ) {

            if (id != undefined) {
                await updateGuia(title.value, description.value, id) ? updateSuccess() : updateErr();
            } else { //CREATE 
                await createGuia(title.value, description.value) ? createSuccess() : createErr();
            }

            obtenerGuias();
            setIsOpen(false);
        }
    }

    async function guiaDelete () {
        const pasos = await getPasos(id);

        pasos.forEach(async paso => {
            if (!await deletePaso(paso.PasosID)) {
                pasoDeleteErr();
            }
        });

        await new Promise(resolve => setTimeout(resolve, 150));
        await deleteGuia(id) ? deleteSuccess() : deleteErr();

        obtenerGuias();
        setIsOpenMD(false);

    }

    function showModalCreate() {
        setInputTitle('');
        setInputDescription('');

        setIsUpdate(false);
        setIsOpen(true);
    }

    async function showModalUpdate(id) {
        const guia = await getGuiaById(id)

        setId(guia.GuiasID)
        setInputTitle(guia.Title);
        setInputDescription(guia.Description);
        setIsUpdate(true);

        setIsOpen(true);
    }

    function showModalDelete(id) {
        setId(id);
        setIsOpenMD(true);
    }

    function showModalCopy(guia) {
        setGuiaCopy(guia);
        setIsOpenCopy(true)
    }

    function redirectGuia(id) {
        navigate(`${ id }`)
    }

    async function copyAction() {       
        document.getElementById("duplicar").className = "btn btn-edit btn-disabled";
        document.getElementById("cancelar").className = "btn btn-disabled";
        let { GuiasID, Title, Description } = guiaCopy;

        const arrTitle = guias.filter( guia => guia.Title.includes(Title) );
        arrTitle.forEach(guia => {
            if (isNaN(guia.Title.slice(-2))) {
                Title = `${guia.Title} 1`
            } else {
                Title = `${guia.Title.slice(0, guia.Title.length - 2)} ${ parseInt(guia.Title.slice(-2)) + 1 }`
            }
        });

        const id = await copyGuia(Title, Description);

        if (!isNaN(id)) {
            await copyPasos(GuiasID, id);
        } else {
            createErr();
        }


        obtenerGuias();
       
        duplicateSuccess();
        document.getElementById("duplicar").className = "btn btn-edit";
        document.getElementById("cancelar").className = "btn";
        setIsOpenCopy(false);
        
    }

    async function copyPasos(copyId, id) {
        const pasos = await getPasos(copyId);


        //order pasos by PasosID
        pasos.sort((a, b) => {
            return a.PasosID - b.PasosID;
        });


        //recorrer pasos y crearlos
        for await (const paso of pasos) {
          
            await createCopyPasos(paso, id);
        }
        
        
    }

    async function createCopyPasos( paso, id ) {

        let pasoId = await copyCreatePaso(id, paso.Title_Message, paso.Message, paso.Title_Question, paso.Question, null, null, null, paso.Label_Affirmative, paso.Label_Negative);

        let Id_Affirmative = paso.Id_Affirmative !== 0 ? pasoId + (paso.Id_Affirmative - paso.PasosID) : 0;
        let Id_Negative = paso.Id_Negative !== 0 ? pasoId + (paso.Id_Negative - paso.PasosID) : 0;
        let Link_Return = isNaN(paso.Link_Return) ? paso.Link_Return : ( paso.Link_Return !== '' ? pasoId + (paso.Link_Return - paso.PasosID) : '');

        if (!isNaN(pasoId)) {
            if (!await updatePaso(id, paso.Title_Message, paso.Message, paso.Title_Question, paso.Question, Id_Affirmative, Id_Negative, Link_Return, paso.Label_Affirmative, paso.Label_Negative, pasoId)) {
                alert('Error al actualizar paso');
                return false;
            }
        } else {
            alert('Error al crear paso');
            return false;
        }

        return true;
    }

    return (
        <>
            <AdminHeader />
            <ToastContainer />

            <div className='container-main'>
                <div className="container-lg">
                    
                    <NavMain btnAction={ showModalCreate } btnText='Crear Guía' />

                    {guias.map( ( guia, index ) => {
                        return <CardGuias key={ index } id={ guia.GuiasID } textId={ ++index } titulo={ guia.Title } descripcion={ guia.Description } actionEdit={ () => showModalUpdate(guia.GuiasID) } actionDelete={ () => showModalDelete(guia.GuiasID) } actionCopy={ () => showModalCopy(guia) } onClickCard={ redirectGuia } />;
                    })}

                </div>
            </div>

            {/* Modal Create/Update */}
            <div className={ `modal ${isOpen ? 'active' : '' }` }>
                <div className='modal-container' onClick={() => setIsOpen(false)}></div>
                <div className='modal-content'>
                    <ModalHeader titulo={ isUpdate ? 'Editar Guía' : 'Crear Guía' } setIsOpen={ setIsOpen } />
                    
                     <div className='modal-body'>
                         <form onSubmit={ e => { isUpdate ? sentForm(e, id) : sentForm(e) }}> 
                             <div>
                                 <label htmlFor='' className='d-block mb-2' >Título de la guía</label>
                                 <input className='form-input' type="text" name='title' value={ inputTitle } onChange={ ({ target }) => setInputTitle(target.value) } />                           
                             </div> 
                             <div>
                                 <label htmlFor='' className='d-block mb-2' >Descripción de la guía</label>
                                 <textarea className='form-input' rows="10" name='description' value={ inputDescription } onChange={ ({ target }) => setInputDescription(target.value) } ></textarea>
                             </div> 
                             <button type='submit' className='btn btn-success btn-lg' > { isUpdate ? 'Actualizar' : 'Guardar' } </button>   
                         </form>
                     </div>
                </div>
            </div>

            
            {/* Modal delete */}
            <ModalDelete isOpen={ isOpenMD } setIsOpen={ setIsOpenMD } text='¿ Eliminar Guía ?' deleteAction={ guiaDelete } />

            <div className={ `modal ${isOpenCopy ? 'active' : '' }` }>
                <div className='modal-container' onClick={() => setIsOpenCopy(false)}></div>
                <div className='modal-content'>
                    <ModalHeader setIsOpen={ setIsOpenCopy } />
                    
                    <div className='modal-body'>
                        <div>
                            <div className='d-flex justify-content-center mb-2'>
                                <FaRegCopy size='1.5rem' />
                            </div>
                            <h3 className='text-center'>¿Duplicar guía?</h3>
                            
                            <div className='d-flex justify-content-around mt-5'>
                                <button id="cancelar" className='btn' onClick={ () => setIsOpenCopy(false) }>Cancelar</button>
                                <button id="duplicar" className='btn btn-edit' onClick={ () => copyAction() }>Duplicar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
    
}