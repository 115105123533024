
// Router
import { Navigate, Route, Routes } from "react-router-dom";
import Articulo from "../pages/Articulo";

// Pages
import Guia from "../pages/Guia";

export default function PreviewRoutes() {
    
    return (
        <Routes>
            <Route path="/guia/:id" element={ <Guia /> } />
            <Route path="/articulo/:id" element={ <Articulo /> } />

            <Route path='/' element={<Navigate to={'/admin'} />} />
            <Route path='/guia' element={<Navigate to={'/admin/guias'} />} />
            <Route path='/articulo' element={<Navigate to={'/admin/articulos'} />} />
        </Routes>
    );
}