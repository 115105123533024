
// Icon
import { BsTrash } from "react-icons/bs";

// Components
import ModalHeader from "./ModalHeader";

export default function ModalDelete({ isOpen, setIsOpen, text, deleteAction }) {
    return (
        <div className={ `modal ${isOpen ? 'active' : '' }` }>
            <div className='modal-container' onClick={() => setIsOpen(false)}></div>
            <div className='modal-content'>
                <ModalHeader setIsOpen={ setIsOpen } />
                
                <div className='modal-body'>
                    <div>
                        <div className='d-flex justify-content-center mb-2'>
                            <BsTrash size='1.5rem' className='color-red' />
                        </div>
                        <h3 className='text-center'>{ text }</h3>
                        <div className='d-flex justify-content-around mt-5'>
                            <button className='btn' onClick={ () => setIsOpen(false) }>Cancelar</button>
                            <button className='btn btn-delete' onClick={ () => deleteAction() }>Eliminar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}