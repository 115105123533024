import Main from "../../layout/Main";
import Bus from "./../../assets/img/bus.png";
import agsLogo from "./../../assets/img/ags_logo.png";
import Reloj from "./../../assets/img/reloj.png";
import Cel from "./../../assets/img/cel.png";
import SS from "./../../assets/img/ss.png";
import Window from "./../../assets/img/window.svg";
import OS from "./../../assets/img/os.svg";
import Logo from "./../../assets/img/logo-simple-white.svg";
import cdmx from "./../../assets/img/logo-ciudad/cdmx.png";
import Wiwi from "./../../assets/img/logo-text-simple-white.svg";
import Chrome from "./../../assets/img/chrome.png";
import Wifi from "./../../assets/img/Wifi.js";
import World from "./../../assets/img/World.js";
import Click from "./../../assets/img/Click.js";
import yovoy from "./../../assets/img/logo_yovoy.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { phoneDevice } from "../../helpers/device";

export default function Home() {
  const navigate = useNavigate();
  const [seccion, setSeccion] = useState(0);
  const [device, setdevice] = useState(undefined);
  const [city, setCity] = useState([]);
  const [red, setRed] = useState('RED_WIWI_WIFI_GRATIS');
  const [logo, setLogo] = useState(Wiwi);
  const [color, setColor] = useState("#71c44d");
  const [colorSecundario, setColorSecundario] = useState("#606060");

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    let phone = phoneDevice();
    phone.os = phone.os.replaceAll("_", ".");
    setdevice(phone);
  }, []);

  useEffect(() => {
    if (document.getElementById("chat24-root") != undefined) {
      document.getElementById("chat24-root").classList.remove("d-none");
    }

    customElements.whenDefined("chat-24").then(() => {
      document
        .querySelector("chat-24")
        .shadowRoot.querySelector("style").innerHTML += `.chat24-container {
        right: 15px !important;
        bottom: 95px !important;
      }
      @media (max-width: 1024px) {
        .startBtn {
          position: fixed !important;
          right: 15px !important;
          bottom: 95px !important;
          width: inherit !important;
        } 
      }`;
      // document.querySelector("chat-24").shadowRoot.querySelector("style").innerHTML += `
      //   .startBtn {
      //     margin-bottom: 150px !important;
      //     margin-right: 5px !important;
      //     padding: 10px !important;
      //     height: 50px !important;
      //     width: 50px !important;
      //   }

      //   .widget--collapsed {
      //     height: 50px !important;
      //     width: 50px !important;
      //   }

      //   .startBtn--bottom {
      //     transform: translateY(0) !important;
      //   }

      //   .widget--bottom {
      //     bottom: 150px;
      //   }
      // `;
    });
  }, []);



  useEffect(() => {
    let city = localStorage.getItem('selectedCity') || 'LEON';


    if (city === 'AGS') {
      setRed('YOVOY_GIGANTE_AGS');
      setLogo(agsLogo);
      setColor("#0a4f91");
      setColorSecundario("#FFFFFF");
    };
    if (city === 'QRO') setRed('QUERETARO_DIGITAL');


    setCity(city);
  }, []);

  const handleSeccion = (seccion) => {
    setSeccion(seccion);
    if (seccion == 2) {
      //espera 1 segundo para hacer el scroll
      setTimeout(() => scrollTo("seccion2"), 100);
    }
  };

  const handleRedirect = () => {
    let OS = device?.os.toUpperCase();
    if (OS.includes("IOS")) {
      if (city == 'QRO') {
        navigate("/articulo/65");
        return;
      }
      if (city == 'AGS') {
        navigate("/articulo/102");
        return;
      }

      navigate("/articulo/61");

    } else { // ANDROID
      if (city == 'QRO') {
        navigate("/articulo/63");
        return;
      }

      console.log(city);

      if (city == 'AGS') {
        navigate("/articulo/100");
        return;
      }

      navigate("/articulo/59");

    }


    // ? navigate("articulo/18")
    // : navigate("articulo/17");
  };

  const [ciudades, setCiudades] = useState([
    "León",
    "San Luis Potosí",
    "Querétaro",
    "Monterrey",
    "Guadalajara",
    "Aguascalientes",
    "Estado de México",
    "Tijuana",
  ]);
  const [showModal, setShowModal] = useState(true);

  // useEffect(() => {
  //   const modalMain = document.querySelector(".container-modal .modal-main");
  //   modalMain.classList.add("show");
  // }, []);

  useEffect(() => {
    const savedCity = localStorage.getItem("selectedCity");
    if (savedCity && ciudades.includes(savedCity)) {
      setShowModal(false);
    }
  }, [ciudades]);

  // guardar en localStorage
  const handleSelectChange = (event) => {
    const selectedCity = event.target.value;
    localStorage.setItem("selectedCity", selectedCity);

    setShowModal(false);

    // Cerrar el al seleccionar una ciudad
    //  const modal = document.querySelector('.container-modal');
    //  modal.classList.add('none');
  };

  // Recuperar el valor guardado
  useEffect(() => {
    const savedCity = localStorage.getItem("selectedCity");
    if (savedCity && ciudades.includes(savedCity)) {
      // Establecer el valor si está guardado
      const selectElement = document.querySelector(".modal-content select");
      selectElement.value = savedCity;
    }
  }, [ciudades]);
  return (
    <Main>
      <div className="container-home">
        <div className="home-width">
          <div className="home-header" style={{ backgroundColor: colorSecundario }}>
            <div className="content-section">
              <div className="header-text">
                {city != 'AGS' ?
                  <h1 style={{ color: color, marginBottom: "20px" }}>Bienvenidos a</h1> :
                  <h1 style={{ color: color, marginBottom: "20px", fontSize: "18px" }}>Bienvenidos a la Red de Internet Gratuito <img src={yovoy} alt="Logo" className="yovoy" /> by wiwi</h1>
                }
                <img src={logo} alt="Logo" />
              </div>
              <img src={city != 'CDMX' ? Logo : cdmx} alt="Wiwi" />

              {/* {city != 'CDMX' ? <img src={cdmx} alt="Wiwi" /> : <img src={Logo} alt="Wiwi" />} */}
            </div>
            <h2 style={{ color: color }}>
              En esta página podrás encontrar la información necesaria para
              poder conectarte a Red <span style={{ color: color }}>{red}</span>.
            </h2>

          </div>
          <div className="home-body">
            <div className="home-section">
              <img src={Bus} alt="Wiwi autobus" className="img-bus" />
              {city != 'AGS' ?
                <p>
                  Te recordamos que para poder conectarte a  {" "}
                  <span className="text-1">{red}</span>{" "}
                  <span className="text-2" style={{ backgroundColor: color, color: colorSecundario }}>deberás estar físicamente</span> en una
                  unidad del Sistema de Transporte o bien en uno de los paraderos
                  que tengan el logo de <span className="text-3">wiwi</span>.
                </p> :
                <p>
                  Te recordamos que para poder conectarte a Red {" "}
                  <span className="text-1">{red}</span>{" "}
                  <span className="text-2" style={{ backgroundColor: color, color: colorSecundario }}>deberás estar físicamente</span> en una
                  unidad del Sistema de Transporte.
                </p>}
            </div>
            <div className="home-section">
              <img src={Reloj} alt="Wiwi time" className="img-time" />
              <p>
                Podrás conectarte por periodos de{" "}
                <span className="text-2" style={{ backgroundColor: color, color: colorSecundario }}>30 minutos</span>, una vez transcurrido
                este tiempo te desconectará de manera automática y solo será
                necesario que te reconectes a Red {" "}
                <span className="text-1">{red}</span>, no hay límite de
                reconexiones y estas se harán de manera automática una vez que
                selecciones la Red <span className="text-1">{red}</span>.
              </p>
            </div>
            <div className="home-section-2">
              <p className="text-center">
                La velocidad promedio a la que podrás conectarte a la Red {red} es de <span className="text-1">3Mbps</span>.
              </p>
            </div>
            <div className="home-section">
              <p>
                En tu primera conexión tendrás que{" "}
                <span className="text-2" style={{ backgroundColor: color, color: colorSecundario }}>registrarte</span> con tu teléfono
                celular.
              </p>
              <img src={Cel} alt="Wiwi phone" className="img-phone" />
              <p>
                Esto con la finalidad de poder darte un servicio más
                personalizado, y para que en tus reconexiones posteriores puedas
                hacerlo de una manera más rápida y fácil ya que tu teléfono ya
                será reconocido dentro de nuestro sistema.
              </p>
            </div>
            <div className="home-section-2">
              <img src={SS} alt="Wiwi SS" className="img-ss" />
              <p className="text-justify">
                Durante el registro en tu primer conexión, recibirás un{" "}
                <span className="text-2" style={{ backgroundColor: color, color: colorSecundario }}>código de 3 dígitos</span> a través de
                un mensaje de SMS el cual ayudará a validar tu teléfono celular.
              </p>
            </div>
            <div className="home-section">
              <ul>
                <li>
                  Es importante mencionar que tus datos NO serán compartidos con
                  nadie ya que nos apegamos a las normas federales establecidas
                  por el IFETEL.
                </li>
                <li>
                  Recuerda que no podrás visitar páginas que tengan contenido
                  para adultos.
                </li>
              </ul>
              <h2 style={{ color: color }}>¡Vamos a conectarnos!</h2>
              <div className="content-sub-section col-3">
                <div className="home-sub-section-2">
                  <span style={{ color: color }}>1</span>
                  <p>Enciende tu Wi-Fi</p>
                  <div className="content-icon">
                    <Wifi />
                  </div>
                </div>
                <div className="home-sub-section-2">
                  <span style={{ color: color }}>2</span>
                  <p>Busca las redes disponibles</p>
                  <div className="content-icon-unfill">
                    <World />
                  </div>
                </div>
                <div className="home-sub-section-2">
                  <span style={{ color: color }}>3</span>
                  <p>
                    Selecciona la Red <span style={{ color: color }}>{red}</span> y conéctate
                    a ella
                  </p>
                  <div className="content-icon">
                    <Click />
                  </div>
                </div>
              </div>
              <p>
                Para ver una guía más detallada para tu primer conexión{" "}
                <span className="text-1">da clic aquí</span>.
              </p>
              <div className="content-btn-special">
                <button className="btn btn-special" onClick={handleRedirect}>
                  {device?.os.toUpperCase().includes("IOS")
                    ? "IOS"
                    : device?.os.toUpperCase().includes("ANDROID")
                      ? "Android"
                      : "Android IOS"}
                </button>
              </div>
            </div>
            <div className="home-section-3" style={{ backgroundColor: color }}>
              <p className="text-center">
                Para lograr una mejor experiencia en la Red {" "}
                <span className="text-1">{red}</span> te hacemos las
                siguientes recomendaciones:
              </p>
            </div>
            <div className="home-section">
              <div className="content-sub-section col-3">
                <div className="home-sub-section">
                  <span style={{ color: color }}>1</span>
                  <p>
                    Intenta tener el menor número de ventanas en tu navegador
                    abiertas
                  </p>
                  <img src={Window} alt="Windows" />
                </div>
                <div className="home-sub-section">
                  <span style={{ color: color }}>2</span>
                  <p>Asegúrate de tener tu sistema operativo actualizado</p>
                  <img src={OS} alt="OS" />
                </div>
                <div className="home-sub-section">
                  <span style={{ color: color }}>3</span>
                  <p>Asegúrate de tener Chrome con la última actualización</p>
                  <img src={Chrome} alt="chrome" />
                </div>
              </div>
              <p>
                Con estos sencillos pasos deberías poder conectarte a la Red {" "}
                <span className="text-1">{red}</span>, de no ser así
                tenemos las siguientes opciones para ayudarte a conectarte de
                manera exitosa.
              </p>
            </div>
            <div className="actions">
              <button
                className="button important"
                onClick={() => handleSeccion(2)}
              >
                <div className="button-wrapper">
                  Botones de ayuda menú inferior
                </div>
              </button>
            </div>
            {seccion == 2 ? (
              <div className="home-section-4">
                <div>
                  <svg viewBox="0 0 259.7 259.7">
                    <polygon
                      class="st0"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      fill="#70D44B"
                      points="244.2,123.8 224.7,205.5 116.9,245.5 36.1,210.9 7.1,123.8 "
                    />
                    <path
                      class="st1"
                      fill="#606060"
                      d="M129.8,0C58.1,0,0,58.1,0,129.8s58.1,129.8,129.8,129.8c71.7,0,129.8-58.1,129.8-129.8S201.6,0,129.8,0z M129.8,226.5c-53.4,0-96.6-43.3-96.6-96.6c0-53.4,43.3-96.6,96.6-96.6c53.4,0,96.6,43.3,96.6,96.6 C226.5,183.2,183.2,226.5,129.8,226.5z"
                    />
                    <g>
                      <path
                        class="st1"
                        fill="#606060"
                        d="M154.6,63.8h-50.9c-7.3,0-13.2,5.9-13.2,13.2v20.8v5.4v19.8h9.5v-21v-4.2v-6.3h58.2v31.8h9.5V77 C167.8,69.7,161.9,63.8,154.6,63.8z M139.4,77.8H119c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h20.4c0.9,0,1.7,0.8,1.7,1.7 C141.1,77.1,140.3,77.8,139.4,77.8z"
                      />
                      <path
                        class="st1"
                        fill="#606060"
                        d="M158.3,170.9H100v-11.7v-12h-9.5v9.2v14.4V185c0,7.3,5.9,13.2,13.2,13.2h50.9c7.3,0,13.2-5.9,13.2-13.2v-37.3 h-9.5L158.3,170.9L158.3,170.9z M129,187.5c-2.5,0-4.6-2-4.6-4.6c0-2.5,2-4.6,4.6-4.6c2.5,0,4.6,2,4.6,4.6 C133.5,185.5,131.5,187.5,129,187.5z"
                      />
                      <path
                        class="st1"
                        fill="#606060"
                        d="M94.9,139.9c1.6,0,3-0.8,3.9-2h10.1c1.1,0,2-0.6,2.4-1.5l4-8.6l3.4,21.4c0.2,1.2,1.1,2.1,2.4,2.2 c0.1,0,0.2,0,0.3,0c1.1,0,2.1-0.7,2.5-1.7l6.9-17.6l4.4,9.9c0.4,1,1.4,1.6,2.5,1.6c1.1,0,2-0.7,2.4-1.7l3.6-8.7l1.3,3.4 c0.4,1,1.4,1.7,2.5,1.7h16.1c1.5,0,2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7h-14.3l-3.1-7.8c-0.4-1-1.4-1.7-2.5-1.7l0,0 c-1.1,0-2.1,0.7-2.5,1.7l-3.7,8.9l-4.4-10c-0.4-1-1.4-1.6-2.5-1.6c-1.1,0-2,0.7-2.4,1.7l-5.6,14.4l-3.2-20.4 c-0.2-1.1-1.1-2.1-2.3-2.2c-1.1-0.1-2.3,0.5-2.8,1.5l-6.9,15.1h-8.4c-0.9-1.2-2.3-2-3.9-2c-2.6,0-4.7,2.1-4.7,4.7 C90.2,137.8,92.3,139.9,94.9,139.9z"
                      />
                    </g>
                  </svg>

                  <h2>Diag</h2>
                  <span>Diagnóstico</span>

                  <p>
                    Revisa si tu celular cumple con los{" "}
                    <span>requisitos básicos</span> para acceder al portal
                  </p>
                </div>
                <div>
                  <svg viewBox="0 0 259.7 259.7">
                    <g>
                      <polygon
                        class="st0"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#70D44B"
                        points="244.2,123.8 224.7,205.5 116.9,245.5 36.1,210.9 7.1,123.8"
                      />
                      <path
                        class="st1"
                        fill="#606060"
                        d="M129.8,0C58.1,0,0,58.1,0,129.8s58.1,129.8,129.8,129.8c71.7,0,129.8-58.1,129.8-129.8S201.6,0,129.8,0z M129.8,226.5c-53.4,0-96.6-43.3-96.6-96.6c0-53.4,43.3-96.6,96.6-96.6c53.4,0,96.6,43.3,96.6,96.6 C226.5,183.2,183.2,226.5,129.8,226.5z"
                      />
                      <g>
                        <path
                          class="st1"
                          fill="#606060"
                          d="M145.9,201.1h-32.1c-1.8,0-3.3-1.5-3.3-3.3v-0.3c0-1.8,1.5-3.3,3.3-3.3h32.1c1.8,0,3.3,1.5,3.3,3.3v0.3 C149.2,199.7,147.7,201.1,145.9,201.1z"
                        />
                        <path
                          class="st1"
                          fill="#606060"
                          d="M151.2,187.1h-42.7c-1.8,0-3.4-1.5-3.4-3.4v-0.1c0-1.8,1.5-3.4,3.4-3.4h42.8c1.8,0,3.4,1.5,3.4,3.4v0.1 C154.6,185.6,153.1,187.1,151.2,187.1z"
                        />
                        <path
                          class="st1"
                          fill="#606060"
                          d="M140.1,212.4h-20.5c-1.5,0-2.7-1.2-2.7-2.7v-1.5c0-1.5,1.2-2.7,2.7-2.7H140c1.5,0,2.7,1.2,2.7,2.7v1.5 C142.7,211.1,141.6,212.4,140.1,212.4z"
                        />
                        <path
                          class="st1"
                          fill="#606060"
                          d="M94.4,151.9c0,0,6.7,7.4,9,14.4c0.7,2,2,3.6,3.9,4.5c2.4,1.2,7.7,2.6,14.4,2.8l0,0c0.1,0,0.3,0,0.5,0 c0.1,0,0.4,0,0.5,0c15.3,0.3,24.6-1.2,28.1-2.9c1.8-0.9,3.2-2.6,3.9-4.5c1-3.1,3-6.3,4.7-9c11.8-9.3,19.4-23.8,19.4-40.3 c0-27.8-21.9-50.4-49-50.4s-49,22.7-49,50.6C80.9,130.7,86.1,142.8,94.4,151.9L94.4,151.9z M92.8,112.1c0.2-0.4,0.4-0.8,0.4-1.2 c0.7-5.1,2-9.3,3.6-12.8c8.4-16.3,25.1-16.2,25.3-16.2c1.7,0,2.9,0.9,2.9,2.7s-1.5,3.4-3.2,3.4c-0.8,0-19.3,0.5-22.7,24.5 c-0.2,1.7-1.6,2.8-3.2,2.8c-0.1,0-0.3,0-0.4,0C93.9,114.9,92.8,113.6,92.8,112.1z"
                        />
                      </g>
                    </g>
                  </svg>

                  <h2>Recom</h2>
                  <span>Recomendaciones generales</span>

                  <p>
                    <span>Logra conectarte</span> de una manera rápida y obtener
                    la mejor experiencia desde tu teléfono celular
                  </p>
                </div>
                <div>
                  <svg viewBox="0 0 259.7 259.7">
                    <g>
                      <polygon
                        class="st0"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        fill="#70D44B"
                        points="248.4,123.8 228.9,205.5 121.1,245.5 40.3,210.9 11.3,123.8 89.7,123.8 127.5,173.8 183.3,123.8"
                      />
                      <path
                        class="st1"
                        fill="#606060"
                        d="M129.8,0C58.1,0,0,58.1,0,129.8s58.1,129.8,129.8,129.8c71.7,0,129.8-58.1,129.8-129.8S201.6,0,129.8,0z M129.8,226.5c-53.4,0-96.6-43.3-96.6-96.6c0-53.4,43.3-96.6,96.6-96.6c53.4,0,96.6,43.3,96.6,96.6 C226.5,183.2,183.2,226.5,129.8,226.5z"
                      />
                      <g>
                        <path
                          class="st1"
                          fill="#606060"
                          d="M130.9,66.6c-32-0.2-59.1,26.5-59.4,58.4c-0.1,14.7,5.2,28.1,13.9,38.5l-0.1-0.1l-7.6,17 c-1,2.3,1.2,4.7,3.6,3.9l21-7.1l0,0c8.6,4.7,18.5,7.3,29.1,7.1c32.2-0.5,57-29.3,56.8-61.4S163.1,66.9,130.9,66.6z M129.7,152.5 c-3.7,0-6.6-2.8-6.6-6.4s2.9-6.5,6.6-6.5c3.6,0,6.4,2.9,6.4,6.5S133.3,152.5,129.7,152.5z M139.7,121.5c-4.6,4.1-6,6-6,8.5 l-0.1,0.8c-0.1,2.2-1.9,3.8-4.1,3.8c-2.3,0-4.3-1.8-4.3-4.1v-1c0-3.6,1.5-6.1,6.6-10.8c5-4.5,6.1-6.2,6.1-9.2c0-3.9-3-6.8-6.8-6.8 c-3.1,0-5.7,1.7-7.3,5c-1,1.8-2.2,2.5-3.8,2.5c-2.2,0-4-1.8-4-4c0-1,0.2-1.8,0.8-2.7c2.7-5.8,7.9-9.1,14.7-9.1 c8.9,0,15.7,6,15.7,14.1C147.2,113.1,145.1,116.7,139.7,121.5z"
                        />
                      </g>
                    </g>
                  </svg>

                  <h2>FAQ</h2>
                  <span>Problemas frecuentes</span>

                  <p>
                    <span>Consulta los problemas más comunes</span> de conexion
                    en nuestras red. Sigue las recomendaciones o pasos sugeridos
                    y logra conectarte
                  </p>
                </div>
              </div>
            ) : (
              <div id="seccion2"></div>
            )}
          </div>
        </div>
      </div>
      {/* {showModal && (
        <div className="container-modal">
          <div className="modal-main">
            <h1>Hola! Bienvenido (a)</h1>
            <div className="modal-header">Selecciona tu ciudad</div>
            <div className="modal-content">
              <select onChange={handleSelectChange}>
                {ciudades.map((ciudad, index) => (
                  <option key={index} value={ciudad}>
                    {ciudad}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )} */}
    </Main>
  );
}
