// React
import React, { useMemo } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// Routes
import AuthRoutes from '../auth/routes/AuthRoutes';
import AdminRoutes from '../admin/routes/AdminRoutes';
import AyudaRoutes from '../ayuda/routes/AyudaRoutes';
import PhoneRoutes from '../phone/routes/PhoneRoutes';
import PreviewRoutes from '../preview/routes/PreviewRoutes';

// Auth
import { useAuth } from '../contexts/auth';


export default function AppRouter() {
    const { auth } = useAuth();
    let { pathname } = useLocation();

    const routes = useMemo(() => {

        if (auth.logged) {
            return (
                <>
                    <Route path='admin/*' element={<AdminRoutes />} />
                    <Route path='preview/*' element={<PreviewRoutes />} />
                    <Route path='auth/*' element={<Navigate to={'/admin'} />} />
                </>
            );
        }

    }, [auth.logged]);

    return (
        <Routes>

            {routes}
            <Route path='auth/*' element={<AuthRoutes />} />
            <Route path='guia/*' element={<AyudaRoutes />} />
            <Route path='/*' element={pathname.includes('/admin') || pathname.includes('preview') ? <Navigate to={'/auth'} /> : <PhoneRoutes />} />
        </Routes>
    );
}