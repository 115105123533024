
// React
import { useEffect, useState } from "react";

// Components
import AdminHeader from "../../components/AdminHeader";
import ModalHeader from "../../components/modal/ModalHeader";
import CardCategoria from "../../components/CardCategoria";
import NavMain from "../../components/NavMain";
import ModalDelete from "../../components/modal/ModalDelete";

// APIs
import { createCategoria, deleteCategoria, getCategoriaById, getCategorias, updateCategoria } from "../api/Categorias";

// Notificaciones
import { ToastContainer, toast } from 'react-toastify';
import { toastConfig } from "../../helpers/notificaciones";

export const createSuccess = () => toast.success('Categoría creada con éxito', toastConfig);
export const createErr = () => toast.error('Error al crear categoría', toastConfig);
export const updateSuccess = () => toast.success('Categoría actualizada con éxito', toastConfig);
export const updateErr = () => toast.error('Error al actualizar categoría', toastConfig);
export const deleteSuccess = () => toast.success('Categoría eliminada con éxito', toastConfig);
export const deleteErr = () => toast.error('Error al eliminar categoría', toastConfig);

export default function Categorias() {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenMD, setIsOpenMD] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [id, setId] = useState(null);

    const [inputName, setInputName] = useState('')
    const [inputColor, setInputColor] = useState('#70D44B')
    const [inputTextColor, setInputTextColor] = useState('#FFFFFF')

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
        obtenerCategorias();
    }, [])

    async function obtenerCategorias() {
        let categorias = await getCategorias();
        setCategorias(categorias);
    }

    async function sentForm (e, id) {
        e.preventDefault();

        const { name, color, text_color } = e.target.elements;

        if ( name.value != '' && color.value != '' && text_color.value != '' ) {

            if (id != undefined) {
                await updateCategoria(name.value, color.value, text_color.value, id) ? updateSuccess() : updateErr()
            } else { //CREATE
                await createCategoria(name.value, color.value, text_color.value) ? createSuccess() : createErr()
            }

            obtenerCategorias();
            setIsOpen(false);
        }
    }

    async function categoriaDelete () {
        await deleteCategoria(id) ? deleteSuccess() : deleteErr();

        obtenerCategorias();
        setIsOpenMD(false);
    }

    function showModalCreate () {
        setInputName('');
        setInputColor('#70D44B');
        setInputTextColor('#FFFFFF');

        setIsUpdate(false);
        setIsOpen(true);
    }

    async function showModalUpdate(id) {
        const categoria = await getCategoriaById(id);
        
        setId(categoria.CategoriasID);
        setInputName(categoria.Name);
        setInputColor(categoria.Color?.includes('#') ? categoria.Color : '#70D44B');
        setInputTextColor(categoria.Text_Color?.includes('#') ? categoria.Text_Color : '#FFFFFF');

        setIsUpdate(true);
        setIsOpen(true);
    }

    function showModalDelete(id) {
        setId(id);
        setIsOpenMD(true);
    }
    
    return (
        <>
            <AdminHeader />
            <ToastContainer />

            <div className='container-main'>
                <div className="container-lg">

                    <NavMain btnAction={ showModalCreate } btnText='Crear categoría' />

                    <div className='row'>
                        {categorias.map(( categoria, index ) => {
                            return (
                                <div className='col-lg-4' key={ index } >
                                    <CardCategoria id={ categoria.CategoriasID } categoria={ categoria.Name } color={ categoria.Color } textColor={ categoria.Text_Color } actionEdit={ () => showModalUpdate(categoria.CategoriasID) } actionDelete={ () => showModalDelete(categoria.CategoriasID) } />
                                </div>
                            );
                        })}
                    </div>

                </div>
            </div>

            {/* Modal Create/Update */}
            <div className={ `modal ${isOpen ? 'active' : '' }` }>
                <div className='modal-container' onClick={() => setIsOpen(false)}></div>
                <div className='modal-content'>
                    <ModalHeader titulo={ isUpdate ? 'Editar categoría' : 'Crear categoría' } setIsOpen={ setIsOpen } />
                    
                    <div className='modal-body'>
                        <form onSubmit={ e => { isUpdate ? sentForm(e, id) : sentForm(e) }}>

                            <div>
                                <label htmlFor='' className='d-block mb-2' >Nombre de la categoría</label>
                                <input className='form-input' type="text" name='name' value={ inputName } onChange={ ({ target }) => setInputName(target.value) } />
                            </div>

                            <div>
                                <label htmlFor='' className='d-block mb-2' >Color de la categoría</label>
                                <div className='form-input-color'>
                                    <input className='input' type="text" name='color' value={ inputColor } onChange={( target ) => setInputColor(target.value)} />
                                    <input className='color' type="color" value={ inputColor } onChange={ ({ target }) => setInputColor(target.value) } />
                                </div>
                            </div>

                            <div>
                                <label htmlFor='' className='d-block mb-2' >Color del texto</label>
                                <div className='form-input-color'>
                                    <input className='input' type="text" name='text_color' value={ inputTextColor } onChange={( target ) => setInputTextColor(target.value)} />
                                    <input className='color' type="color" value={ inputTextColor } onChange={ ({ target }) => setInputTextColor(target.value) } />
                                </div>
                            </div>

                            <button type='submit' className='btn btn-success btn-lg'>{ isUpdate ? 'Actualizar' : 'Guardar' }</button>

                        </form>
                    </div>
                </div>
            </div>

            {/* Modal delete */}
            <ModalDelete isOpen={ isOpenMD } setIsOpen={ setIsOpenMD } text='¿ Eliminar categoría ?' deleteAction={ categoriaDelete } />
        </>
    );
}