import Main from "../../layout/Main";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCiudadesList } from "../../api/Ciudades";

import leon from "./../../assets/img/logo-ciudad/leon.png";
import slp from "./../../assets/img/logo-ciudad/slp.png";
import qro from "./../../assets/img/logo-ciudad/qro.png";
import mty from "./../../assets/img/logo-ciudad/mty.jpg";
import gdl from "./../../assets/img/logo-ciudad/gdl.jpg";
import ags from "./../../assets/img/logo-ciudad/ags.png";
import edomex from "./../../assets/img/logo-ciudad/edomex.png";
import tij from "./../../assets/img/logo-ciudad/tijuana.jpg";
import celaya from "./../../assets/img/logo-ciudad/celaya.png";
import cdmx from "./../../assets/img/logo-ciudad/cdmx.png";

export default function Inicio() {


  const navigate = useNavigate();

  const imagenesCiudades = [
    { name: "AGS", img: ags },
    { name: "CELAYA", img: celaya },
    { name: "CDMX", img: cdmx },
    { name: "EDOMEX", img: edomex },
    { name: "GDL", img: gdl },
    { name: "LEON", img: leon },
    { name: "MTY", img: mty },
    { name: "QRO", img: qro },
    { name: "SLP", img: slp },
    { name: "TIJ", img: tij }
  ];

  const [ciudades, setCiudades] = useState([]);

  useEffect(() => { obtenerCiudades(); }, []);
  const obtenerCiudades = async () => { setCiudades(await getCiudadesList()); };

  const handleClick = (id, ciudad) => {
    localStorage.setItem("selectedCity", ciudad);
    navigate("./inicio");
    // Aquí puedes hacer algo con el ID si es necesario
  };


  return (
    <Main>
      <div class="main-container">
        <div class="logo-container">
          <svg
            width="98"
            height="73"
            viewBox="0 0 98 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M42.203 7.48834C44.3587 7.48834 46.1062 5.81202 46.1062 3.74417C46.1062 1.67632 44.3587 0 42.203 0C40.0473 0 38.2998 1.67632 38.2998 3.74417C38.2998 5.81202 40.0473 7.48834 42.203 7.48834Z"
              fill="#70D44B"
            />
            <path
              d="M56.1747 7.48834C58.3304 7.48834 60.0779 5.81202 60.0779 3.74417C60.0779 1.67632 58.3304 0 56.1747 0C54.019 0 52.2715 1.67632 52.2715 3.74417C52.2715 5.81202 54.019 7.48834 56.1747 7.48834Z"
              fill="#70D44B"
            />
            <path
              d="M49.1892 37.0279C38.315 37.0279 27.9308 32.6737 20.6986 25.0827C19.7058 24.0416 19.782 22.4247 20.8673 21.4739C21.9527 20.5215 23.6382 20.5947 24.6294 21.6358C30.8558 28.1702 39.8075 31.919 49.1892 31.919C58.5709 31.919 67.5226 28.1717 73.749 21.6358C74.7402 20.5947 76.4257 20.5215 77.511 21.4739C78.5963 22.4263 78.6726 24.0416 77.6798 25.0827C70.4476 32.6737 60.0634 37.0279 49.1892 37.0279Z"
              fill="#70D44B"
            />
            <path
              d="M49.189 24.1053C42.1142 24.1053 35.3558 21.2606 30.6479 16.301C29.6583 15.2584 29.7362 13.6415 30.8247 12.6923C31.9117 11.743 33.5956 11.8177 34.5868 12.8619C38.2873 16.7601 43.61 18.9963 49.1906 18.9963C54.7697 18.9963 60.0924 16.7601 63.7945 12.8603C64.784 11.8177 66.4696 11.7414 67.5565 12.6907C68.6435 13.64 68.723 15.2568 67.7334 16.2995C63.0222 21.2606 56.2654 24.1053 49.189 24.1053Z"
              fill="#70D44B"
            />
            <path
              d="M47.2103 72.8329H40.5308C40.4444 72.8329 40.3755 72.7654 40.3755 72.6839V55.215C40.3755 55.132 40.4458 55.066 40.5308 55.066H47.2103C47.2967 55.066 47.3656 55.1335 47.3656 55.215V72.6839C47.3656 72.7668 47.2953 72.8329 47.2103 72.8329Z"
              fill="#111111"
            />
            <path
              d="M79.4459 55.1574L75.3076 63.1657L71.2688 55.1602C71.2395 55.1025 71.1794 55.066 71.1135 55.066H69.0428H66.9722C66.9062 55.066 66.8447 55.1025 66.8169 55.1602L62.7781 63.1657L58.6397 55.1574C58.6104 55.1011 58.5504 55.0646 58.4844 55.0646H50.807C50.6766 55.0646 50.5931 55.1967 50.6531 55.3078L60.1872 72.7429C60.2166 72.7977 60.2766 72.8315 60.3411 72.8315H64.9396C65.0056 72.8315 65.0671 72.7949 65.0964 72.7373L69.0443 64.8189L72.9922 72.7373C73.0215 72.7949 73.0816 72.8315 73.149 72.8315H77.7475C77.812 72.8315 77.8706 72.7977 77.9014 72.7429L87.4355 55.3078C87.4956 55.1967 87.412 55.0646 87.2816 55.0646H79.6013C79.5353 55.066 79.4753 55.1011 79.4459 55.1574Z"
              fill="#111111"
            />
            <path
              d="M97.4881 72.8329H90.8087C90.7222 72.8329 90.6533 72.7654 90.6533 72.6839V55.215C90.6533 55.132 90.7237 55.066 90.8087 55.066H97.4881C97.5746 55.066 97.6434 55.1335 97.6434 55.215V72.6839C97.6449 72.7668 97.5746 72.8329 97.4881 72.8329Z"
              fill="#111111"
            />
            <path
              d="M94.1484 52.4514C96.0786 52.4514 97.6434 50.9504 97.6434 49.0988C97.6434 47.2472 96.0786 45.7462 94.1484 45.7462C92.2181 45.7462 90.6533 47.2472 90.6533 49.0988C90.6533 50.9504 92.2181 52.4514 94.1484 52.4514Z"
              fill="#111111"
            />
            <path
              d="M29.1696 55.1574L25.0312 63.1657L20.9925 55.1602C20.9632 55.1025 20.9031 55.066 20.8371 55.066H18.7665H16.6958C16.6299 55.066 16.5683 55.1025 16.5405 55.1602L12.5018 63.1657L8.36338 55.1574C8.33407 55.1011 8.27399 55.0646 8.20805 55.0646H0.530649C0.400226 55.0646 0.316698 55.1967 0.376781 55.3078L9.91088 72.7429C9.94018 72.7977 10.0003 72.8315 10.0647 72.8315H14.6633C14.7292 72.8315 14.7908 72.7949 14.8201 72.7373L18.7679 64.8189L22.7158 72.7373C22.7451 72.7949 22.8052 72.8315 22.8726 72.8315H27.4711C27.5356 72.8315 27.5942 72.7977 27.625 72.7429L37.1591 55.3078C37.2192 55.1967 37.1357 55.0646 37.0052 55.0646H29.3234C29.259 55.066 29.1989 55.1011 29.1696 55.1574Z"
              fill="#111111"
            />
            <path
              d="M43.8705 52.4514C45.8008 52.4514 47.3656 50.9504 47.3656 49.0988C47.3656 47.2472 45.8008 45.7462 43.8705 45.7462C41.9403 45.7462 40.3755 47.2472 40.3755 49.0988C40.3755 50.9504 41.9403 52.4514 43.8705 52.4514Z"
              fill="#111111"
            />
          </svg>
        </div>
        <div class="todos-container">
          <p>todos conectados</p>
        </div>

        <div class="internet-container">
          <p>Internet Gratis</p>
        </div>

        <div class="sel-ciudad">
          <p>Selecciona tu ciudad.</p>
        </div>

        <div class="cards-container">
          {ciudades.sort((a, b) => {
            if (a.Abreviatura < b.Abreviatura) {
              return -1;
            }
            if (a.Abreviatura > b.Abreviatura) {
              return 1;
            }
            return 0;
          }).map((ciudad, index) => {
            let imagen = imagenesCiudades.find(img => img.name == ciudad.Abreviatura);
            return (
              <div>
                <div class="card-ciudad" id={`${ciudad.CiudadesID}`} onClick={() => handleClick(ciudad.CiudadesID, ciudad.Abreviatura)}>
                  {imagen ?
                    <img src={imagen.img} alt="Ciudad" width="100%" height="100%" /> :
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
                      <path d="M217 25v30h30V25h-30zm48 0v30h30V25h-30zm48 0v30h30V25h-30zm-96 48v46h30V73h-30zm48 0v46h30V73h-30zm48 0v46h30V73h-30zm-96 64v46h30v-46h-30zm48 0v46h30v-46h-30zm48 0v46h30v-46h-30zm174 6.508l-94 53.715V215h94v-71.492zM217 201v46h30v-46h-30zm48 0v46h30v-46h-30zm48 0v46h30v-46h-30zm80 32v110h94V233h-94zM39 256v23h18v-23H39zm178 9v46h30v-46h-30zm48 0v46h30v-46h-30zm48 0v46h30v-46h-30zM37.562 297l-7 14h146.875l-7-14H37.563zM25 329v158h23v-23h32v23h48v-23h32v23h23V329H25zm192 0v46h30v-46h-30zm48 0v46h30v-46h-30zm48 0v46h30v-46h-30zM48 352h32v16H48v-16zm80 0h32v16h-32v-16zm265 9v126h31v-23h32v23h31V361h-94zM48 384h32v16H48v-16zm80 0h32v16h-32v-16zm89 9v46h30v-46h-30zm48 0v46h30v-46h-30zm48 0v46h30v-46h-30zM48 416h32v16H48v-16zm80 0h32v16h-32v-16zm89 41v30h30v-30h-30zm48 0v30h30v-30h-30zm48 0v30h30v-30h-30z"></path>
                    </svg>
                  }
                </div>
                <div class="ciudad">{ciudad.Abreviatura}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Main>
  );
}
