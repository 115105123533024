
// Https
import http from "../../helpers/https";

const url = '/ayuda/guias';

export async function getGuias() {
    try {
        const response = await http.get(url);
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function getGuiaById(id) {
    try {
        const response = await http.get(url, { params: { id } });
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function createGuia(title, description) {
    try {
        const response = await http.post(url, { title, description });
        return response.data.data.id_guia != undefined ? true : false
    } catch (err) {
        return false;
    }
}

export async function copyGuia(title, description) {
    try {
        const response = await http.post(url, { title, description });
        return response.data.data.id_guia;
    } catch (err) {
        return false;
    }
}

export async function updateGuia(title, description, id) {
    try {
        const response = await http.put(url, { title, description, id });
        return JSON.stringify(response.data.data) != '{}' ? true : false;
    } catch (err) {
        return false;
    }
}

export async function deleteGuia(id) {
    try {
        const response = await http.delete(url, { data: { id } })
        return response.data.data.DELETE == 'OK' ? true : false;
    } catch (err) {
        return false;
    }
}