import { useEffect, useState } from "react";
import Header from "../../components/Header";

// Helpers
import {phoneDevice} from "../../helpers/device";

import {getRequisitos} from "../../admin/api/Requisitos";
import Main from "../../layout/Main";

export default function Diagnostics() {

    const [device, setDevice] = useState(undefined);
    const [requisitos, setRequisitos] = useState([]);

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
        obtenerInfoPhone();
        obtenerRequisitos();
    }, []);

    function obtenerInfoPhone() {
        let device = phoneDevice();
        device.os = device.os.replaceAll("_", ".");
        setDevice(device);
    }

    async function obtenerRequisitos() {
        const requisitos = await getRequisitos();
        requisitos.forEach((requisito) => {
            requisito.Versiones = JSON.parse(requisito.Versiones);
        });
        setRequisitos(requisitos);
    }

    function getBrowser() {
        const browser = device.browser.split(" ")[0];
        const version = device.browser.split(" ")[1];
        let requisito = requisitos.filter((requisito) => {
            return requisito.Navegador.toUpperCase().includes(browser.toUpperCase()) ? requisito : "";
        });

        if (requisito.length > 0) {
            requisito = requisito[0];

            return parseFloat(version) >= parseFloat(requisito.Version_Minima) ? <li className="mild"><p>Tu navegador cumple con la versión mínima requerida</p></li> : <li className="urgent"><p>Tu navegador NO cumple con la versión mínima requerida</p></li>;
        } else {
            return <li className="urgent"><p>No pudimos determinar tu navegador.</p></li>;
        }
    }

    function getSO() {
        const so = device.os.split(" ")[0];
        let version = device.os.split(" ")[1];

        if (so.toUpperCase().includes("MAC")) {
            let arr = device.os.split(" ");
            version = arr[arr.length - 1];
        }

        let requisito = requisitos.filter((requisito) => {
            return requisito.SO.toUpperCase().includes(so.toUpperCase()) ? requisito : "";
        });

        if (requisito.length > 0) {
            requisito = requisito[0];

            return parseFloat(version) >= parseFloat(requisito.Version_Minima) ? <li className="mild"><p>Tu sistema operativo cumple con la versión mínima requerida</p></li> : <li className="urgent"><p>Tu sistema operativo NO cumple con la versión mínima requerida</p></li>;
        } else {
            return <li className="urgent"><p>No pudimos determinar tu sistema operativo.</p></li>;
        }
    }

    return (
        <Main>
            <Header section='diagnostics' notLogo={true} title='Diagnóstico de Cel' />
            <div className="container-phone">
                <div className="diagnostics">
                    <div>
                        {/* <h2></h2> */}

                        <ul className="phone-summary">
                            <li className="phone-os">
                                <p>
                                    <strong>OS:</strong> {device === undefined ? "" : device.os}
                                </p>

                                <p>
                                    <strong>Navegador:</strong> {device === undefined ? "" : device.browser}
                                </p>
                            </li>

                            <li className="phone-device">
                                <p>
                                    <strong>Equipo</strong>
                                </p>
                                {device === undefined ? "" : <p>{device.mobile.type}</p>}
                                {device === undefined ? "" : <p>{device.mobile.model}</p>}
                            </li>

                            <li>
                                <p>
                                    <strong>Especificaciones</strong>
                                </p>
                                {device === undefined ? "" : <p>{device.userAgent}</p>}
                            </li>
                        </ul>

                        <div className="results">
                            <ul className="list-of-problems">
                                {device !== undefined ? getBrowser() : ""}
                                {device !== undefined ? getSO() : ""}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
}