

import { httpOP } from "../helpers/https";


const url = '/ciudades';


export async function getCiudadesList() {
    try {
        const response = await httpOP.get(url);
        return response.data;
    } catch (err) {
        return err;
    }
}
