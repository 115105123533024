
// Router
import { Navigate, Route, Routes } from "react-router-dom";

// Pages 
import Articulos from "../pages/Articulos";
import Categorias from "../pages/Categorias";
import Guias from "../pages/Guias";
import GuiaById from "../pages/GuiasById";
import Requisitos from "../pages/Requisitos";

export default function AdminRoutes() {
    
    return (
        <Routes>
            <Route path="/articulos" element={ <Articulos /> } />
            <Route path="/categorias" element={ <Categorias /> } />
            <Route path="/guias" element={ <Guias /> } />
            <Route path="/guias/:id" element={ <GuiaById /> } />
            <Route path="/requisitos" element={ <Requisitos /> } />

            <Route path='/' element={<Navigate to={'articulos'} />} />
        </Routes>
    );
}