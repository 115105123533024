import { Navigate, Route, Routes } from "react-router-dom";
import Guia from "../../phone/pages/Guia";
import Ayuda from "../pages/Ayuda";

export default function AyudaRoutes() {
    
    return (
        <Routes>
            {/* <Route path="/" element={ <Guia /> } /> */}
            {/* <Route path="/" element={ <Navigate to={'/'} /> } /> */}
            <Route path=":id" element={ <Ayuda /> } />
        </Routes>
    );
}