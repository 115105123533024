
// Https
import http from "../../helpers/https";

const url = '/ayuda/articulos';


export async function getArticulos() {
    try {
        const response = await http.get(url);
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function getArticuloById(id) {

    try {
        const response = await http.get(url, { params: { id } });
        console.log(response.data);
        return response.data;
    } catch (err) {
        return err;
    }

}

export async function createArticulo(title, message, categorias, link) {
    try {
        const response = await  http.post(url, { title, message, categorias, link });
        return response.data.id_articulo !== undefined ? true : false;
    } catch (err) {
        return false;
    }
}

export async function updateArticulo(title, message,categorias, link, id) {
    try {
        const response = await http.put(url, { title, message,categorias, link, id });
        return JSON.stringify(response.data.data) != '{}' ? true : false;
    } catch (err) {
        return false;
    }

}

export async function deleteArticulo(id) {
    try {
       
        const response = await http.delete(url, { data: { id } });
        return response.data.data.DELETE == 'OK' ? true : false;
    } catch (err) {
        return false;
    }
}

export async function uploadFile(file, type) {
    try {
        const response = await http.post("ayuda/upload", file, {
            headers: {
                'Content-Type': type
            }
        });
        return response.data.file !== undefined ? response.data.file : null;
    } catch (err) {
        return false;
    }
}