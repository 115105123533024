
// React
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";


// Components
import Header from "../../components/Header";
import Body from "../../components/ayuda/Body";

// API
import { getPasos } from "../../admin/api/Pasos";


export default function Ayuda() {


    const [pasos, setPasos] = useState([]);
    const [paso, setPaso] = useState(undefined);
    const [pasoId, setPasoId] = useState([]);
    const [pasoIndex, setPasoIndex] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

    const { id } = useParams();
    const back = searchParams.get('back');

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
        obtenerPasos();
    }, []);


    async function obtenerPasos() {
        let pasos = await getPasos(id);

        setPasos(pasos);
        setPaso(pasos[0]);
        setPasoIndex(pasos[0].PasosID);
    }

    function handlePaso(id) {
        setPasoId(pasoId => [...pasoId, paso.PasosID]);
        setPaso( pasos.filter( paso => paso.PasosID === id)[0] );
    }

    function handleBack(back) {
        if (back !== '') {
            if (isNaN(back)) {
                window.location.href = back;
            } else {
                setPaso( pasos.filter( paso => paso.PasosID == back)[0] );
                setPasoId(pasoId => pasoId.slice(1));
            }   
        } else {
            setPaso( pasos.filter( paso => paso.PasosID === pasoId[pasoId.length - 1])[0] );
            setPasoId(pasoId => pasoId.slice(0, -1));
        }
    }

    return (
        <>
            <Header />

            <div className='container-ayuda'>
                { paso != undefined ? <Body titleMessage={ paso.Title_Message } message={ paso.Message } titleQuestion={ paso.Title_Question } question={ paso.Question } left={ paso.Id_Negative } right={ paso.Id_Affirmative } onClickLeft={ () => handlePaso(paso.Id_Negative) } onClickRight={ () => handlePaso(paso.Id_Affirmative) } onClickBack={ () => handleBack(paso.Link_Return) } index={ pasoIndex } id={ paso.PasosID } LabelRight={ paso.Label_Affirmative } labelLeft={ paso.Label_Negative } /> : '' }
            </div>
            
        </>
    );
}