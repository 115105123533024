export default function Word() {
	return (
		<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
			<desc></desc>
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<circle cx="12" cy="12" r="9"></circle>
			<line x1="3.6" y1="9" x2="20.4" y2="9"></line>
			<line x1="3.6" y1="15" x2="20.4" y2="15"></line>
			<path d="M11.5 3a17 17 0 0 0 0 18"></path>
			<path d="M12.5 3a17 17 0 0 1 0 18"></path>
		</svg>
	);
}