
// Icon
import { FaRegTimesCircle } from "react-icons/fa";

export default function ModalHeader({ titulo, setIsOpen }) {
    return (
        <div className='modal-header'>
            { titulo != undefined ? <h2>{ titulo }</h2> : <div></div> }

            <div className='modal-times'>
                <button className="btn btn-success" onClick={() => {setIsOpen(false)}}>
                    <svg className="icon" version="1.1" height="1em" width="1em" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 352 273.9">
                        <path d="M109.3,9.4L9,114.4c-6,5.8-9,13.7-9,22.4c0,8.7,3,16.5,9,22.4l100.3,105.4c11.9,12.5,31.3,12.5,43.2,0s11.9-32.7,0-45.2L104.4,169h217c16.9,0,30.6-14.3,30.6-32s-13.7-32-30.6-32h-217l48.2-50.4c11.9-12.5,11.9-32.7,0-45.2C140.6-3.1,121.3-3.1,109.3,9.4z"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}