
// Https
import http from "../../helpers/https";

const url = '/ayuda/requisitos';

export async function getRequisitos() {
    try {
        const response = await http.get(url);
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function getRequisitoById(id) {
    try {
        const response = await http.get(url, { params: { id } } );
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function createRequisito(so, navegador, version, versiones) {
    try {
        const response = await http.post(url, { so, navegador, version, versiones });
        return response.data.id_pasos != undefined ? true : false;
    } catch (err) {
        return false;
    }
}

export async function updateRequisito(so, navegador, version, versiones, id) {
    try {
        const response = await http.put(url, { so, navegador, version, versiones, id });
        return response.data.data.UPDATE == 'OK' ? true : false;
    } catch (err) {
        return false;
    }
}


export async function deleteRequisito(id) {
    try {
        const response = await http.delete(url, { data: { id } });
        return response.data.data.DELETE == 'OK' ? true : false;
    } catch (err) {
        return false;
    }
}