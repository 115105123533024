import axios from 'axios';

const baseUrl = 'https://dev-exporters.wiwi.services';
const baseUrlOP = 'https://dev-operaciones.wiwi.services/monitoreo';

export const http = axios.create({ baseURL: baseUrl });
export const httpOP = axios.create({ baseURL: baseUrlOP });

export default http;

