
// Icon
import { useEffect, useState } from "react";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";



// APIs
import { getPasos } from "../admin/api/Pasos";



export default function CardGuias({ id, textId, titulo, descripcion, actionEdit, actionDelete, actionCopy, onClickCard }) {


    const [isCollapsed, setIsCollapsed] = useState(true);
    const [pasos, setPasos] = useState();

    useEffect(() => {
        obtenerPasos();
    }, [])
    
    async function obtenerPasos() {
        let pasos = await getPasos(id);
        setPasos(pasos.length)
    }


    function clickCard({ target }) {

        if (target.attributes.class != undefined && target.attributes.class.value == 'more') {
            setIsCollapsed(!isCollapsed);
            return
        }

        onClickCard( id )
    }
    
    return (
        <div className="basic-card d-flex">

            <div className='d-flex justify-content-between align-items-center mr-4 cursor-pointer w-100' onClick={ clickCard }>
                <div className='card-width-30 d-flex'>
                    <div className="container-id mr-2">
                        <p>{ textId }</p>
                    </div>
                    <div className="container-title">
                        <p>{ titulo }</p>
                    </div>
                </div>

                <div className='container-description card-width-50'>
                    { descripcion.length > 115 ? isCollapsed ? <p>{ descripcion.slice(0, 115) } <span className='more'>...ver más</span></p> : <p>{ descripcion } <span className='more'>...ver menos</span></p> : descripcion }
                </div>

                <div className='container-steps'>
                    <p>{ pasos } pasos</p>
                </div>
            </div>

            <div className='container-action d-flex align-items-center gap-15'>
                <button className="btn btn-sm btn-edit" onClick={() => actionEdit()}>
                    <BsPencilSquare className='white' size='1rem' />
                </button>
                <button className="btn btn-sm btn-delete" onClick={() => actionDelete()}>
                    <BsTrash className='white' size='1rem' />
                </button>
                <button className="btn btn-sm btn-copy" onClick={() => actionCopy()}>
                    <FaRegCopy className='white' size='1rem' />
                </button>
            </div>
        </div>
    );
}