
// Icon
import { BsPencilSquare, BsTrash } from "react-icons/bs";

export default function CardCategoria({ id, categoria, color, textColor, actionEdit, actionDelete }) {

    return (
        <div className='card-categoria'>
            <div className='container-categoria-id'>
                <p>{ id }</p>
            </div>
            <div className='container-categoria-nombre'>
                <p className='categoria-item' style={{ backgroundColor: color, color: textColor }}>{ categoria }</p>
            </div>
            <div className='container-categoria-action d-flex'>
                <button className="btn btn-sm mr-3 btn-edit" onClick={() => actionEdit()}>
                    <BsPencilSquare className='white' size='1rem' />
                </button>
                <button className="btn btn-sm btn-delete" onClick={() => actionDelete()}>
                    <BsTrash className='white' size='1rem' />
                </button>
            </div>
        </div>
    );
}