// Imagenes
import Logo from "../assets/img/logo-horizontal.svg";

// Icon
import {AiOutlineMenu} from "react-icons/ai";
import {FiArrowLeftCircle} from "react-icons/fi";

// Router
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";

import { useNavigate } from "react-router-dom";

export default function Header({url, actionNavbarMenu, section, notLogo, title}) {

    const navigate = useNavigate();

    const [isActive, setIsActive] = useState(false);

    function handleMenu(id) {
        setIsActive(!isActive);
        actionNavbarMenu(id);
    }

    return (
        <>
            <div className={`navbar  ${isActive ? "active" : ""}`}>
                <div className="wrapper container-sm">
                    {!notLogo ?
                    <Link to='/' onClick={() => navigate('/')}>
                        <img src={Logo} width="95px" alt="Logo" />
                    </Link> : <h2 className="header-title">{title ?? ''}</h2>}

                    <div className="nav-button">
                        {url !== undefined ? (
                            <Link to={url}>
                                <button className="btn btn-success" style={{ width: '100%', height: '100%' }}>
                                    <svg className="icon" version="1.1" height="1em" width="1em" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 352 273.9">
                                        <path d="M109.3,9.4L9,114.4c-6,5.8-9,13.7-9,22.4c0,8.7,3,16.5,9,22.4l100.3,105.4c11.9,12.5,31.3,12.5,43.2,0s11.9-32.7,0-45.2L104.4,169h217c16.9,0,30.6-14.3,30.6-32s-13.7-32-30.6-32h-217l48.2-50.4c11.9-12.5,11.9-32.7,0-45.2C140.6-3.1,121.3-3.1,109.3,9.4z"/>
                                    </svg>
                                </button>
                            </Link>
                        ) : (
                            <span className={`menu cursor-pointer  ${isActive ? "active" : ""}`}>
                                {!isActive ? (
                                    section == 'intro' ?
                                    // section.length == 1 ?
                                    <svg className="icon" viewBox="0 0 1024 1024" onClick={() => setIsActive(!isActive)}>
                                        <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
                                    </svg> :
                                    <button className="btn btn-success" style={{ width: '100%', height: '100%' }} onClick={() => navigate(-1)}>
                                        <svg className="icon" version="1.1" height="1em" width="1em" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 352 273.9">
                                            <path d="M109.3,9.4L9,114.4c-6,5.8-9,13.7-9,22.4c0,8.7,3,16.5,9,22.4l100.3,105.4c11.9,12.5,31.3,12.5,43.2,0s11.9-32.7,0-45.2L104.4,169h217c16.9,0,30.6-14.3,30.6-32s-13.7-32-30.6-32h-217l48.2-50.4c11.9-12.5,11.9-32.7,0-45.2C140.6-3.1,121.3-3.1,109.3,9.4z"/>
                                        </svg>
                                    </button>
                                ) : (
                                    <svg className="icon" viewBox="0 0 16 16" width="16" onClick={() => setIsActive(!isActive)}>
                                        <polygon fill-rule="evenodd" points="8 9.414 3.707 13.707 2.293 12.293 6.586 8 2.293 3.707 3.707 2.293 8 6.586 12.293 2.293 13.707 3.707 9.414 8 13.707 12.293 12.293 13.707 8 9.414" />
                                    </svg>
                                )}
                            </span>
                        )}
                    </div>
                </div>

                <div className={`navbar-menu`}>
                    <ul className="content-item">
                        <li className={section == "intro" ? "active" : ""} onClick={() => handleMenu("intro")}>Inicio</li>
                        <li className={section == "diagnostics" ? "active" : ""} onClick={() => handleMenu("diagnostics")}>Diagnosticar celular</li>
                        <li className={section == "suggestions" ? "active" : ""} onClick={() => handleMenu("suggestions")}>Recomendaciones generales</li>
                        <li className={section == "search" ? "active" : ""} onClick={() => handleMenu("search")}>Problemas y preguntas frecuentes</li>
                        <li className={section == "guia" ? "active" : ""} onClick={() => handleMenu("guia")}>Guías</li>
                    </ul>
                </div>
            </div>
        </>
    );
}
