
// Https
import http from "../../helpers/https";

const url = '/ayuda/pasos';

export async function getPasos(guiasid) {
    try {
        const response = await http.get(url, { params: { guiasid } });
        return response.data;
    } catch (err) {
        return err;
    }
}


export async function getPasoById(id) {
    try {
        const response = await http.get(url, { params: { id } } );
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function createPaso(guiasid, title_message, message, title_question, question, id_affirmative, id_negative, link_return, label_affirmative, label_negative) {
    try {
        const response = await http.post(url, { guiasid, title_message, message, title_question, question, id_affirmative, id_negative, link_return, label_affirmative, label_negative });
        return response.data.id_pasos != undefined ? true : false;
    } catch (err) {
        return false;
    }
}

export async function copyCreatePaso(guiasid, title_message, message, title_question, question, id_affirmative, id_negative, link_return, label_affirmative, label_negative) {
    try {
        const response = await http.post(url, { guiasid, title_message, message, title_question, question, id_affirmative, id_negative, link_return, label_affirmative, label_negative });
        return response.data.id_pasos;
    } catch (err) {
        return false;
    }
}

export async function updatePaso(guiasid, title_message, message, title_question, question, id_affirmative, id_negative, link_return, label_affirmative, label_negative, id) {
    try {
        const response = await http.put(url, { guiasid, title_message, message, title_question, question, id_affirmative, id_negative, link_return, label_affirmative, label_negative, id });
        return JSON.stringify(response.data.data) != '{}' ? true : false;
    } catch (err) {
        return false;
    }
}


export async function deletePaso(id) {
    try {
        const response = await http.delete(url, { data: { id } });
        return response.data.data.DELETE == 'OK' ? true : false;
    } catch (err) {
        return false;
    }
}