// React
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Sass
import 'react-toastify/dist/ReactToastify.css';
import './sass/main-prueba.sass';


// Router
import AppRouter from './router/AppRouter';

// Auth
import { AuthProvider } from './contexts/auth';

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <AppRouter />
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);