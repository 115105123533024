

// Icon
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";

// Scroll
import { Link, animateScroll as scroll } from "react-scroll";

const maxLength = 30;


export default function CardPasos({ id, cardId, titleMessage, message, titleQuestion, question, LinkYes, LinkNo, LinkReturn, actionEdit, actionDelete,LabelYes,LabelNo }) {

    function addClass( id ) {
        document.querySelectorAll('.card').forEach(element => element.classList.remove('card-scroll-active'));
        document.getElementById(id).classList.add('card-scroll-active');
    }
    
    return (
        <div className='card' id={ cardId }>
            <div className='d-flex justify-content-between align-items-center mr-4 w-100'>
                <div className='d-flex align-items-center w-40'>
                    <div className="container-id mr-3">
                        <p>{ id }</p>
                    </div>
                    <div>
                        <div className="container-message">
                            { titleMessage !== '' ? <p className='tooltip top'>{ titleMessage.slice(0, maxLength) } <span>{ titleMessage }</span> </p> : <></> }
                            { message !== '' ? <p className='tooltip top'>{ message.slice(0, maxLength) } <span>{ message }</span> </p> : <></> }
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center w-40'>
                    <div>
                        <div className="container-question">
                            { titleQuestion !== '' ? <p className='tooltip top'>{ titleQuestion.slice(0, maxLength) } <span>{ titleQuestion }</span> </p> : <></> }
                            { question !== '' ? <p className='tooltip top'>{ question.slice(0, maxLength) } <span>{ question }</span> </p> : <></> }
                        </div>
                    </div>
                </div>

                <div className="container-options d-flex justify-content-center w-50">
                    <div className='container-item mr-4'>
                        <Link className='item negative cursor-pointer' to={`${LinkNo}`} smooth={ true } duration={ 500 } onClick={ () => addClass(LinkNo) }>{LabelNo? LabelNo:'NO'}</Link>
                        { LinkNo === 0 ? <FaRegTimesCircle size='1.5rem' className='times' /> : <FaRegCheckCircle size='1.5rem' className='check' /> }
                    </div>
                    <div className='container-item mr-4'>
                        { isNaN(LinkReturn) ? <a href={LinkReturn === 'None' || LinkReturn === '' ? '#' : LinkReturn} className='item return cursor-pointer' target="_blank">RETURN</a> : <Link className='item return cursor-pointer' to={`${LinkReturn}`} smooth={ true } duration={ 500 } onClick={ () => addClass(LinkReturn) }>RETURN</Link> }
                        { LinkReturn === 'None' || LinkReturn === '' ? <FaRegTimesCircle size='1.5rem' className='times' /> : <FaRegCheckCircle size='1.5rem' className='check' /> }
                    </div>
                    <div className='container-item mr-4'>
                        <Link className='item positive cursor-pointer' to={`${LinkYes}`} smooth={ true } duration={ 500 } onClick={ () => addClass(LinkYes) }>{LabelYes?LabelYes:'YES'}</Link>
                        { LinkYes === 0 ? <FaRegTimesCircle size='1.5rem' className='times' /> : <FaRegCheckCircle size='1.5rem' className='check' /> }
                    </div>
                </div>
            </div>

            <div className='container-action'>
                <button className="btn btn-sm mr-3 btn-edit" onClick={() => actionEdit()}>
                    <BsPencilSquare className='white' size='1rem' />
                </button>
                <button className="btn btn-sm btn-delete" onClick={() => actionDelete()}>
                    <BsTrash className='white' size='1rem' />
                </button>
            </div>
        </div>
    );
}