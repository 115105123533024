
// Icon
import { AiOutlinePlusCircle } from "react-icons/ai";

export default function NavMain({ btnAction, btnText, btnTwo, btnActionTwo, btnTextTwo, iconTwo, classBtnTwo }) {

    return (
        <div className='mb-3 container-main-header'>
            <div></div>
            <div className='d-flex gap-20'>
                { btnTwo !== false && btnTwo !== undefined ?
                <button className={`btn ${ classBtnTwo }`} onClick={() => btnActionTwo()}>
                    { iconTwo }
                    { btnTextTwo }
                </button> : '' }
                <button className='btn btn-success' onClick={() => btnAction()}>
                    <AiOutlinePlusCircle className='mr-2 white' size='1rem' />
                    { btnText }
                </button>
            </div>
        </div>
    );
}