import { useEffect, useState } from "react";
import Header from "../../components/Header";

import { AiOutlineSearch } from "react-icons/ai";
import { phoneDevice } from "../../helpers/device";
import { getArticulos } from "../../admin/api/Articulos";
import { getGuias } from "../../admin/api/Guias";

import Modal from "../../components/modal/Modal";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import Main from "../../layout/Main";

export default function Search() {

    const navigate = useNavigate();

    const [device, setDevice] = useState(undefined);
    const [isOpen, setIsOpen] = useState(false)
    const [filterArticulos, setFilterArticulos] = useState([]);
    const [articulos, setArticulos] = useState([]);
    const [tags, setTags] = useState([]);
    const [articulo, setArticulo] = useState({});
    const [guias, setGuias] = useState([]);
    const [filterGuias, setFilterGuias] = useState([]);
    const [city, setCity] = useState([]);

    useEffect(() => {
        let city = localStorage.getItem('selectedCity') || 'LEON';
        setCity(city);
    }, []);

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) { document.getElementById('chat24-root').classList.add('d-none') }
        obtenerInfoPhone();
    }, []);

    useEffect(() => {
        if (device != undefined) {
            obtenerArticulos();
            obtenerGuias();
        }
    }, [device])

    useEffect(() => {
        document.querySelectorAll('img[alt="emoji"]').forEach(el => { el.classList.add('img-inline'); })
    }, [isOpen])

    function obtenerInfoPhone() {
        let device = phoneDevice();
        device.os = device.os.replaceAll("_", ".");
        setDevice(device);
    }
    async function obtenerArticulos() {
        let articulos = await getArticulos();
        let arr = articulos;

        arr = arr.filter(articulo => !articulo.categorias.includes(2));
        arr = arr.filter(articulo => !articulo.categorias.includes(34));

        if (city === 'QRO') {
            arr = arr.filter(articulo => articulo.categorias.includes(36));
        } else if (city === 'AGS') {
            arr = arr.filter(articulo => articulo.categorias.includes(37));
        } else {
            arr = arr.filter(articulo => articulo.categorias.includes(35));
        }

        let a = [];
        arr.forEach(element => {
            if (element.Title.includes('Primera Conexión') || element.Title.includes('Reconexión')) {
                a.push(element);
            }
        });
        arr.forEach(element => {
            if (!element.Title.includes('Primera Conexión') && !element.Title.includes('Reconexión')) {
                a.push(element);
            }
        });

        arr = a;

        switch (true) {
            case device.os.toUpperCase().includes('ANDROID'):
                arr = arr.filter(articulo => articulo.categorias.includes(16));

                setArticulos(arr);
                setFilterArticulos(arr);

                setTags(tag => [...tag, {
                    Name: 'ANDROID',
                    Color: 'rgb(59, 212, 128)',
                    Text_Color: 'rgb(255, 255, 255)',
                }]);
                break;

            case device.os.toUpperCase().includes('IOS'):
                arr = arr.filter(articulo => articulo.categorias.includes(18));

                setArticulos(arr);
                setFilterArticulos(arr);
                setTags(tag => [...tag, {
                    Name: 'IOS',
                    Color: 'rgb(7, 7, 7)',
                    Text_Color: 'rgb(255, 255, 255)',
                }]);
                break;

            default:

                setArticulos(arr);
                setFilterArticulos(arr);
                setTags(tag => [...tag, {
                    Name: 'ANDROID',
                    Color: 'rgb(59, 212, 128)',
                    Text_Color: 'rgb(255, 255, 255)',
                }, {
                    Name: 'IOS',
                    Color: 'rgb(7, 7, 7)',
                    Text_Color: 'rgb(255, 255, 255)',
                }]);
                break;
        }
    }

    async function obtenerGuias() {
        const guias = await getGuias();

        switch (true) {
            case device.os.toUpperCase().includes('ANDROID'):
                setGuias(
                    guias.filter(guia => {
                        return !guia.Title.toUpperCase().includes('IOS');
                    })
                );
                setFilterGuias(
                    guias.filter(guia => {
                        return !guia.Title.toUpperCase().includes('IOS');
                    })
                );
                break;

            case device.os.toUpperCase().includes('IOS'):
                setGuias(
                    guias.filter(guia => {
                        return !guia.Title.toUpperCase().includes('ANDROID');
                    })
                );
                setFilterGuias(
                    guias.filter(guia => {
                        return !guia.Title.toUpperCase().includes('ANDROID');
                    })
                );
                break;

            default:
                setGuias(guias);
                setFilterGuias(guias);
                break;
        }
    }

    function handleSearch({ target }) {
        const value = target.value;
        setFilterArticulos(
            articulos.filter((articulo) => {
                return articulo.Title.toUpperCase().includes(value.toUpperCase());
            })
        );
    }

    function handleModal(articulo, open) {
        setArticulo(articulo);
        setIsOpen(open);
    }

    function handleURL(url) {
        navigate(url);
    }

    return (
        <Main>
            <Header section='search' notLogo={true} title='Problemas Frecuentes' />
            <div className="container-phone">
                <div className="problems">
                    <div className="head">
                        <h2>Buscar</h2>

                        <div className="search">
                            <input type="text" placeholder="Escribe tu problema..." onChange={handleSearch} />
                            <AiOutlineSearch size='1.5rem' className='white' />
                        </div>
                    </div>

                    <div className="content-tags">
                        <div className="tags">
                            {tags.map((tag, index) => {
                                return <span style={{ background: tag.Color, color: tag.Text_Color }} className="categories">{tag.Name}</span>;
                            })}
                        </div>
                    </div>

                    <ul className="list-of-problems">
                        {filterArticulos.map((articulo, index) => {
                            return (
                                <li key={index}>
                                    <div className='content-tags cursor-pointer' onClick={() => handleModal(articulo, true)}>
                                        <span className="title">{articulo.Title}</span>
                                        {/* <div className='tags'>
                                            {categorias.filter((categoria) => articulo.categorias.includes(categoria.CategoriasID)).slice(0, 3).map( categoria => {
                                                return <span className="categories" style={{ background: categoria.Color, color: categoria.Text_Color }}>{ categoria.Name }</span>
                                            })}
                                        </div> */}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <div className='modal-body'>
                    <h2 className='mb-4'>{articulo.Title}</h2>
                    <div className="modal-editor">
                        {articulo.Message != undefined ? parse(articulo.Message) : ''}
                    </div>
                </div>
                <div className='modal-footer'>
                    {/* ¿No pudiste resolver tu problema? pulsa <a className="link" href="https://c2d.to/soportewiwi" target="_blank">aquí</a> */}
                    <p>Pulsa <a className="link" href="https://c2d.to/soportewiwi" target="_blank">aquí</a> para recibir ayuda por <a className="link" href="https://c2d.to/soportewiwi" target="_blank">WhatsApp</a></p>
                    {/* <p>¿No pudiste resolver tu problema? pulsa <a className="link" href="https://c2d.to/soportewiwi" target="_blank">aquí</a></p> */}
                </div>
                {/* { articulo.Link !== '' && articulo.Link !== 'None' && articulo.Link !== null ?
                <div className='modal-footer'>
                    
<p>¿No pudiste resolver tu problema? pulsa { isNaN(articulo.Link) ? <a className='link' target="_blank" href={articulo.Link}>aquí</a> : <span onClick={() => handleURL(`/guia/${articulo.Link}`, 'search')}  className='link'>aquí</span> } </p>                </div> : <></> } */}
            </Modal>
        </Main>
    );
}