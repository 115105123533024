// React
import { useState, useEffect, useCallback } from "react";

// Components
import CardArticulo from "../../components/CardArticulo";
import AdminHeader from "../../components/AdminHeader";
import NavMain from "../../components/NavMain";
import ModalHeader from "../../components/modal/ModalHeader";
import ModalDelete from "../../components/modal/ModalDelete";
import ModalClone from "../../components/modal/ModalClone";

// Tiptap
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

// Tiptap Extencions
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import Youtube from "@tiptap/extension-youtube";

// APIs
import {
  createArticulo,
  deleteArticulo,
  getArticuloById,
  getArticulos,
  updateArticulo,
  uploadFile,
} from "../api/Articulos";
import { getCategorias } from "../api/Categorias";
import { getGuias } from "../api/Guias";

// Notificaciones
import { ToastContainer, toast } from "react-toastify";
import { toastConfig } from "../../helpers/notificaciones";
import Modal from "../../components/modal/Modal";

function MenuBar({ editor, setLink, setIsOpenModal, setIsEmiji }) {
  if (!editor) {
    return null;
  }

  return (
    <>
      <span
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`key-btn ${
          editor.isActive("heading", { level: 1 }) ? "active" : ""
        }`}
      >
        h1
      </span>
      <span
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`key-btn ${
          editor.isActive("heading", { level: 2 }) ? "active" : ""
        }`}
      >
        h2
      </span>
      <span
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={`key-btn ${
          editor.isActive("heading", { level: 3 }) ? "active" : ""
        }`}
      >
        h3
      </span>
      <span
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={`key-btn ${
          editor.isActive("heading", { level: 4 }) ? "active" : ""
        }`}
      >
        h4
      </span>
      <span
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={`key-btn ${
          editor.isActive("heading", { level: 5 }) ? "active" : ""
        }`}
      >
        h5
      </span>
      <span
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={`key-btn ${
          editor.isActive("heading", { level: 6 }) ? "active" : ""
        }`}
      >
        h6
      </span>
      <span
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`key-btn ${editor.isActive("bold") ? "active" : ""}`}
      >
        Bold
      </span>
      <span
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`key-btn ${editor.isActive("bulletList") ? "active" : ""}`}
      >
        ul
      </span>
      <span
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`key-btn ${editor.isActive("orderedList") ? "active" : ""}`}
      >
        ol
      </span>
      <span
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={`key-btn ${editor.isActive("underline") ? "active" : ""}`}
      >
        u
      </span>
      <span
        onClick={setLink}
        className={`key-btn ${editor.isActive("link") ? "active" : ""}`}
      >
        link
      </span>
      <span
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive("link")}
        className="key-btn"
      >
        Quitar link
      </span>
      <span onClick={() => setIsOpenModal(true)} className="key-btn">
        Agregar imagen
      </span>
      <span onClick={() => setIsOpenModal(false)} className="key-btn">
        Agregar video
      </span>
      <span
        onClick={() => {
          setIsOpenModal(true);
          setIsEmiji(true);
        }}
        className="key-btn"
      >
        Agregar imagen en texto
      </span>
    </>
  );
}

export const createSuccess = () =>
  toast.success("Articulo creado con éxito", toastConfig);
export const createErr = () =>
  toast.error("Error al crear articulo", toastConfig);
export const updateSuccess = () =>
  toast.success("Articulo actualizado con éxito", toastConfig);
export const updateErr = () =>
  toast.error("Error al actualizar articulo", toastConfig);
export const deleteSuccess = () =>
  toast.success("Articulo eliminado con éxito", toastConfig);
export const deleteErr = () =>
  toast.error("Error al eliminar articulo", toastConfig);

export default function Articulos() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMD, setIsOpenMD] = useState(false);
  const [isOpenModalClone, setIsOpenModalClone] = useState(false);
  const [articleToClone, setArticleToClone] = useState({});

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isEmoji, setIsEmiji] = useState(false);
  const [isActive, setIsActive] = useState(undefined);

  const [articulos, setArticulos] = useState([]);
  const [articulo, setArticulo] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [guias, setGuias] = useState([]);
  const [id, setId] = useState(null);

  const [inputTitle, setInputTitle] = useState("");
  const [inputLink, setInputLink] = useState("");
  const [inputCategorias, setInputCategorias] = useState([]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Image.configure({
        inline: true,
      }),
      Youtube.configure({
        controls: false,
      }),
    ],
    content: ``,
    editorProps: {
      attributes: {
        spellcheck: "false",
      },
    },
  });

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  async function formSubmit(e, id) {
    e.preventDefault();

    const data = editor.getHTML();

    const { title } = e.target.elements;

    let container = document.querySelector("#listaCategorias");
    let checkBoxesSelected = container.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let listaCategorias = [];

    for (var i = 0; i < checkBoxesSelected.length; i++) {
      listaCategorias.push(parseInt(checkBoxesSelected[i].id));
    }

    if (title.value !== "" && data !== "") {
      if (id == 0) {
        (await createArticulo(title.value, data, listaCategorias, inputLink))
          ? createSuccess()
          : createErr();
      } else {
        (await updateArticulo(
          title.value,
          data,
          listaCategorias,
          inputLink,
          id
        ))
          ? updateSuccess()
          : updateErr();
      }

      obtenerArticulos();
      setIsOpen(false);
    }
  }

  async function articuloDelete() {
    (await deleteArticulo(id)) ? deleteSuccess() : deleteErr();

    obtenerArticulos();
    setIsOpenMD(false);
  }

  function showModalCreate() {
    editor.commands.setContent("");
    setArticulo({});
    setInputTitle("");
    setInputLink("");
    setIsActive(undefined);
    setInputCategorias([]);
    categorias.forEach((categoria) => {
      setInputCategorias((inputCategorias) => [
        ...inputCategorias,
        { id: categoria.CategoriasID, Name: categoria.Name, value: false },
      ]);
    });

    setIsOpen(true);
  }
  const showModalClone = (articuloL, categorias) => {
    articuloL.categorias = categorias;
    setArticleToClone(articuloL);
    setIsOpenModalClone(true);
  };

  async function showModalUpdate(id) {
    let articulo = await getArticuloById(id);
    editor.commands.setContent(articulo.Message);
    setInputTitle(articulo.Title);
    setInputLink(articulo.Link);

    if (articulo.Link == "") {
      setIsActive(undefined);
    } else {
      isNaN(articulo.Link) ? setIsActive(false) : setIsActive(true);
    }

    setInputCategorias([]);
    categorias.filter((categoria) =>
      articulo.categorias.includes(categoria.CategoriasID)
        ? setInputCategorias((inputCategorias) => [
            ...inputCategorias,
            { id: categoria.CategoriasID, Name: categoria.Name, value: true },
          ])
        : setInputCategorias((inputCategorias) => [
            ...inputCategorias,
            { id: categoria.CategoriasID, Name: categoria.Name, value: false },
          ])
    );

    setArticulo(articulo);
    document.querySelectorAll('img[alt="emoji"]').forEach((el) => {
      el.classList.add("img-inline");
    });
    setIsOpen(true);
  }

  function showModalDelete(id) {
    setId(id);
    setIsOpenMD(true);
  }

  const articuloClone = async (articuloToClone) => {
    console.log(articuloToClone);
    try {
      const clonedArticulo = {
        ...articuloToClone,
        Title: `${articuloToClone.Title} - Copia`,
      };

      // Clonar articulo en la base de datos
      console.log(clonedArticulo);
      const categoriaIDs = clonedArticulo.categorias.map(
        (categorias) => categorias.index
      );

      console.log(categoriaIDs);

      // const response = await createArticulo(clonedArticulo);
      const response = await createArticulo(
        clonedArticulo.Title,
        clonedArticulo.Message,
        clonedArticulo.categorias,
        clonedArticulo.Link
      );

      // Si la creación fue exitosa en la base de datos, actualiza el estado local
      if (response) {
        setArticulos([...articulos, clonedArticulo]);
        toast.success("Artículo clonado con éxito", toastConfig);
      } else {
        // Manejo del caso en el que la creación en la base de datos falla
        toast.error("Error al clonar el artículo", toastConfig);
      }

      setIsOpenModalClone(false);
    } catch (error) {
      // Manejo de errores en caso de que la llamada falle
      console.log("Error al clonar el artículo:", error);
      toast.error("Error al clonar el artículo", toastConfig);
    }
    obtenerArticulos();
  };

  async function obtenerArticulos() {
    let articulos = await getArticulos();
    let categorias = await getCategorias();

    setCategorias(categorias);

    for (let i = 0; i < articulos.length; i++) {
      if (articulos[i].categorias.length > 0) {
        let cate = [];
        for (let j = 0; j < articulos[i].categorias.length; j++) {
          for (let k = 0; k < categorias.length; k++) {
            if (articulos[i].categorias[j] === categorias[k].CategoriasID) {
              cate.push({
                name: categorias[k].Name,
                color: categorias[k].Color,
                textColor: categorias[k].Text_Color,
              });
            }
          }
        }
        articulos[i].categorias = cate;
      }
    }
    setArticulos(articulos);
    setLoading(false);
  }

  async function obtenerGuias() {
    setGuias(await getGuias());
  }

  useEffect(() => {
    if (document.getElementById("chat24-root") != undefined) {
      document.getElementById("chat24-root").classList.add("d-none");
    }
    obtenerArticulos();
    obtenerGuias();
  }, []);

  function handleSwitch(isActive) {
    setIsActive(isActive);
    setInputLink("");
  }

  function handleCheckbox(index) {
    let arr = [...inputCategorias];
    arr[index].value = !arr[index].value;

    setInputCategorias(arr);
  }

  function handleIsImage(isImage) {
    setIsImage(isImage);
    setIsOpenModal(true);
  }

  async function addImage() {
    const [file] = document.getElementById("editor_image").files;

    if (file) {
      console.log(editor.extensionStorage);
      const url = await uploadFile(file, file.type);
      editor
        .chain()
        .focus()
        .setImage({ src: url, alt: isEmoji ? "emoji" : "" })
        .run();
      document.querySelectorAll('img[alt="emoji"]').forEach((el) => {
        el.classList.add("img-inline");
      });
      setIsOpenModal(false);
      setIsEmiji(false);
    }
  }

  function addVideo() {
    const url = document.getElementById("link_video").value;

    editor.commands.setYoutubeVideo({
      src: url,
      width: "100%",
    });
    setIsOpenModal(false);
  }

  function previewImage({ target }) {
    const [file] = target.files;

    if (file) {
      document.getElementById("preview_image").src = URL.createObjectURL(file);
    }
  }

  return (
    <>
      <AdminHeader />
      <ToastContainer />

      <div className="container-main">
        <div className="container-lg">
          <NavMain btnAction={showModalCreate} btnText="Crear artículo" />
          {loading ? (
            <></>
          ) : (
            articulos.map((articuloL, index) => {
              return (
                <CardArticulo
                  categoriasList={categorias}
                  articuloL={articuloL}
                  key={index}
                  id={++index}
                  ids={articuloL.ArticulosID}
                  titulo={articuloL.Title}
                  categorias={articuloL.categorias}
                  actionEdit={() => showModalUpdate(articuloL.ArticulosID)}
                  actionClone={(articuloL, categorias) =>
                    showModalClone(articuloL, categorias)
                  }
                  actionDelete={() => showModalDelete(articuloL.ArticulosID)}
                  actionPreview={() =>
                    (window.location.href = `/articulo/${articuloL.ArticulosID}`)
                  }
                />
              );
            })
          )}
        </div>
      </div>

      <div className={`modal ${isOpen ? "active" : ""}`}>
        <div className="modal-container" onClick={() => setIsOpen(false)}></div>
        <div className="modal-content" style={{ maxWidth: "800px" }}>
          <ModalHeader
            titulo={
              JSON.stringify(articulo) === "{}"
                ? "Crear articulo"
                : "Editar articulo"
            }
            setIsOpen={setIsOpen}
          />

          <div className="modal-body">
            <form
              onSubmit={(e) => {
                JSON.stringify(articulo) == "{}"
                  ? formSubmit(e, 0)
                  : formSubmit(e, articulo.ArticulosID);
              }}
            >
              <div>
                <label htmlFor="" className="d-block mb-2">
                  Título del artículo
                </label>
                <input
                  className="form-input"
                  type="text"
                  name="title"
                  value={inputTitle}
                  onChange={({ target }) => {
                    setInputTitle(target.value);
                  }}
                />
              </div>

              <div>
                <label htmlFor="" className="d-block mb-2">
                  Artículo
                </label>
                <div className="editor-container">
                  <div className="keys-container">
                    <MenuBar
                      editor={editor}
                      setLink={setLink}
                      setIsOpenModal={(isImage) => handleIsImage(isImage)}
                      setIsEmiji={() => setIsEmiji(true)}
                    />
                  </div>

                  <div className="editor">
                    <EditorContent editor={editor} />
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <p className="mb-2">Categorías</p>

                <div className="form-input-checkbox" id="listaCategorias">
                  {inputCategorias.map((categoria, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <input
                          type="checkbox"
                          name="categoria"
                          id={categoria.id}
                          checked={categoria.value}
                          onChange={() => handleCheckbox(index)}
                        />
                        <label htmlFor={categoria.id}>{categoria.Name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="mb-4">
                <p className="mb-2">Link de ayuda</p>
                <div className="input-switch">
                  <button
                    type="button"
                    className={`left ${isActive ? "active" : ""}`}
                    onClick={() => handleSwitch(true)}
                  >
                    Guía
                  </button>
                  <button
                    type="button"
                    className={`right ${
                      !isActive && isActive !== undefined ? "active" : ""
                    }`}
                    onClick={() => handleSwitch(false)}
                  >
                    Link externo
                  </button>
                </div>
              </div>

              {isActive ? (
                <div>
                  <label htmlFor="" className="d-block mb-2">
                    Guía
                  </label>
                  <select
                    name="guia"
                    className="form-select"
                    onChange={({ target }) => setInputLink(target.value)}
                  >
                    <option value="">Selecciona una guía</option>
                    {guias.map((guia) => {
                      return (
                        <option
                          selected={
                            guia.GuiasID == inputLink ? "selected" : false
                          }
                          value={guia.GuiasID}
                        >
                          {guia.Title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                ""
              )}

              {!isActive && isActive !== undefined ? (
                <div>
                  <label htmlFor="" className="d-block mb-2">
                    Link externo
                  </label>
                  <input
                    className="form-input"
                    type="text"
                    name="link_externo"
                    value={inputLink}
                    onChange={({ target }) => setInputLink(target.value)}
                  />
                </div>
              ) : (
                ""
              )}

              <button
                type="submit"
                className="btn btn-success btn-lg"
                onClick={() => setIsOpen(false)}
              >
                {JSON.stringify(articulo) === "{}" ? "Guardar" : "Actualizar"}
              </button>
            </form>
          </div>
        </div>
      </div>

      <ModalDelete
        isOpen={isOpenMD}
        setIsOpen={setIsOpenMD}
        text="¿Eliminar articulo?"
        deleteAction={articuloDelete}
      />
      <ModalClone
        isOpen={isOpenModalClone}
        setIsOpen={setIsOpenModalClone}
        text="¿Clonar artículo?"
        cloneAction={articuloClone}
        articleToClone={articleToClone}
      />

      <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <div className="modal-body">
          {isImage ? (
            <div className="text-center">
              <img src="" alt="" id="preview_image" className="w-100" />
              <input
                type="file"
                id="editor_image"
                className="d-none"
                onChange={previewImage}
              />
              <label
                className="btn btn-sm btn-edit d-block"
                style={{ width: "50%", margin: "auto" }}
                htmlFor="editor_image"
              >
                Seleccionar archivo
              </label>
            </div>
          ) : (
            <div>
              <label htmlFor="" className="d-block mb-2">
                Link del video
              </label>
              <input
                className="form-input"
                type="text"
                name="link_video"
                id="link_video"
                autoFocus={true}
              />
            </div>
          )}

          <div className="d-flex justify-content-around mt-5">
            <button
              className="btn"
              onClick={() => {
                setIsOpenModal(false);
                setIsEmiji(false);
              }}
            >
              Cancelar
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                isImage ? addImage() : addVideo();
              }}
            >
              Aceptar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
