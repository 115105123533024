import { Navigate, Route, Routes } from "react-router-dom";
import Articulo from "../pages/Articulos";
import Inicio from "../pages/Inicio";
import Diagnostics from "../pages/Diagnostics";
import Home from "../pages/Home";
import Phone from "../pages/Phone";
import Search from "../pages/Search";
import Suggestions from "../pages/Suggestions";

export default function PhoneRoutes() {
    
    return (
        <Routes>
            <Route path="/" element={ <Inicio /> } />
            {/* <Route path="/" element={ <Phone /> } /> */}
            <Route path="/diagnostics" element={ <Diagnostics /> } />
            <Route path="/inicio" element={ <Home /> } />
            <Route path="/search" element={ <Search /> } />
            <Route path="/suggestions" element={ <Suggestions /> } />
            <Route path="/articulo/:id" element={ <Articulo /> } />
            <Route path='/*'element={<Navigate to={'/'} />} />
        </Routes>
    );
}