
// Router
import { Link, useLocation, useNavigate } from "react-router-dom";

// Imagenes
import Logo from '../assets/img/logo-horizontal.svg';

// Icon
import { MdOutlineArticle, MdOutlineLogout } from "react-icons/md";
import { BsTools } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { HiMenu } from "react-icons/hi";
import { GiSmartphone } from "react-icons/gi";

// Media Query
import { useMediaQuery } from '@mui/material';

// Auth
import { useAuth } from "../contexts/auth";


export default function AdminHeader() {

    const auth = useAuth();
    const navigate = useNavigate();
    const mobile = useMediaQuery('(max-width: 768px)');
    const location = useLocation();

    function logout() {
        auth.logout();
        navigate('/auth/login');
    }

    return (
        <div className='navbar-admin'>
            <div className='wrapper container-lg'>
                <img src={Logo} width='95px' alt="Logo" />

                { mobile ?
                <HiMenu className='admin-menu' size={'1.5rem'} />
                :
                <div className='d-flex'>
                    <Link to='/admin/articulos' className={ `navbar-item ${location.pathname == '/admin/articulos' ? 'active' : '' }` } > <MdOutlineArticle size={'1.5rem'} className='mr-2' /> Artículos</Link>
                    <Link to='/admin/guias' className={ `navbar-item ${location.pathname.includes('/admin/guias') ? 'active' : '' }` } > <BsTools size={'1.2rem'} className='mr-2' /> Guías</Link>
                    <Link to='/admin/categorias' className={ `navbar-item ${location.pathname == '/admin/categorias' ? 'active' : '' }` } > <BiCategory size={'1.5rem'} className='mr-2' /> Categorías</Link>
                    <Link to='/admin/requisitos' className={ `navbar-item ${location.pathname == '/admin/requisitos' ? 'active' : '' }` } > <GiSmartphone size={'1.5rem'} className='mr-2' /> Requisitos</Link>
                    <li className='navbar-item cursor-pointer' onClick={ logout } > <MdOutlineLogout size={'1.5rem'} className='mr-2' /> Cerrar sesión</li>
                </div>
                }
            </div>
        </div>
    );
}