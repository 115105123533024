// Icon
import {HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight} from "react-icons/hi";
import {BsArrowLeftCircle} from "react-icons/bs";

export default function Body({titleMessage, message, titleQuestion, question, onClickLeft, onClickRight, onClickBack, index, id, labelLeft, LabelRight, left, right}) {
    return (
        <>
            <div className="content">
                <div className="messge-container">
                    {titleMessage !== '' ? <h2>{titleMessage}</h2> : <></>}
                    {message !== '' ? <p>{message}</p> : <></>}
                </div>

                <div className="question-container">
                    {titleQuestion !== '' ? <h2>{titleQuestion}</h2> : <></> }
                    {question !== '' ? <p>{question}</p> : <></> }
                </div>
            </div>
            <div className="option-container">
                {left !== 0 ? (
                    <button className="btn btn-option" onClick={() => onClickLeft()}>
                        <HiOutlineArrowNarrowLeft className="mr-2 white" /> {labelLeft !== "" && labelLeft !== undefined ? labelLeft : "No"}{" "}
                    </button>
                ) : (
                    <div></div>
                )}
                {index !== id ? (
                // {index !== id || index !== "" ? (
                    <button className="btn btn-back mobile" onClick={() => onClickBack()}>
                        <BsArrowLeftCircle className="mr-2 white" /> Regresar{" "}
                    </button>
                ) : (
                    ""
                )}
                {right !== 0 ? (
                    <button className="btn btn-option" onClick={() => onClickRight()}>
                        {" "}
                        {LabelRight !== "" && LabelRight !== undefined ? LabelRight : "Si"} <HiOutlineArrowNarrowRight className="ml-2 white" />
                    </button>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
}
