
import { useLocation, useNavigate } from "react-router-dom";

export default function Main({ children }) {

    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <>
            {children}
            <footer className="footer">
                <div className="container">
                    <div className="options">
                        <button className={`btn ${pathname == '/inicio' ? 'active' : ''}`} onClick={() => navigate("/inicio")}>
                            <svg viewBox="0 0 32.8 21.7">
                                <path d="M20.2,4.4c1.2,0,2.2-1,2.2-2.2S21.4,0,20.2,0S18,1,18,2.2S19,4.4,20.2,4.4z" />
                                <path d="M12.5,4.4c1.2,0,2.2-1,2.2-2.2S13.7,0,12.5,0s-2.2,1-2.2,2.2S11.3,4.4,12.5,4.4z" />
                                <path d="M6,10.2c2.9,2.4,6.5,3.7,10.3,3.7s7.4-1.3,10.3-3.8c0.7-0.6,0.8-1.8,0.2-2.5c-0.6-0.8-1.8-0.8-2.5-0.2 c-2.2,1.9-5.1,2.9-8,2.9s-5.8-1-8-2.9C7.5,6.8,6.4,6.9,5.8,7.6C5.2,8.5,5.3,9.6,6,10.2z"/>
                                <path d="M16.3,21.7c6,0,11.7-2.3,15.9-6.6c0.7-0.7,0.7-1.8,0-2.5s-1.8-0.7-2.5,0c-3.6,3.6-8.3,5.5-13.4,5.5 c-5,0-9.7-1.9-13.3-5.4c-0.7-0.7-1.8-0.7-2.5,0s-0.7,1.8,0,2.5C4.8,19.4,10.4,21.7,16.3,21.7z"/>
                            </svg>
                            <label>Inicio</label>
                        </button>
                        <button className={`btn ${pathname == '/diagnostics' ? 'active' : ''}`} onClick={() => navigate("/diagnostics")}>
                            <svg className="icon" viewBox="0 0 200 200">
                                <path
                                    d="M138.2,0H62.4C51.6,0,42.8,8.8,42.8,19.6v30.9v8v29.5h14.1V56.8v-6.3v-9.4h86.7v47.4h14.1V19.6
                                        C157.8,8.8,149,0,138.2,0z M115.5,20.9H85.1c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.2-2.6,2.6-2.6h30.4c1.4,0,2.6,1.2,2.6,2.6
                                        C118.1,19.8,116.9,20.9,115.5,20.9z"
                                />
                                <path
                                    d="M143.6,159.4H56.9V142v-17.8H42.8v13.7v21.5v21c0,10.8,8.8,19.6,19.6,19.6h75.8c10.8,0,19.6-8.8,19.6-19.6
                                        v-55.5h-14.1V159.4z M100,184.2c-3.7,0-6.8-3-6.8-6.8c0-3.7,3-6.8,6.8-6.8c3.7,0,6.8,3,6.8,6.8C106.8,181.2,103.8,184.2,100,184.2z
                                        "
                                />
                                <path
                                    d="M49.2,113.3c2.4,0,4.5-1.2,5.8-3H70c1.6,0,3-0.9,3.6-2.3l5.9-12.8l5,31.9c0.3,1.8,1.7,3.1,3.5,3.3
                                        c0.1,0,0.3,0,0.4,0c1.6,0,3.1-1,3.7-2.5l10.3-26.2l6.5,14.7c0.6,1.5,2.1,2.4,3.7,2.4c1.6,0,3-1,3.6-2.5l5.3-12.9l2,5
                                        c0.6,1.5,2.1,2.5,3.7,2.5h24c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4h-21.3l-4.6-11.6c-0.6-1.5-2.1-2.5-3.7-2.5c0,0,0,0,0,0
                                        c-1.6,0-3.1,1-3.7,2.5l-5.5,13.3l-6.6-14.9c-0.6-1.5-2.1-2.4-3.7-2.4c-1.6,0-3,1-3.6,2.5l-8.4,21.4l-4.8-30.4
                                        c-0.3-1.7-1.6-3.1-3.4-3.3c-1.7-0.2-3.4,0.7-4.1,2.3l-10.3,22.5H55c-1.3-1.8-3.4-3-5.8-3c-3.9,0-7,3.1-7,7
                                        C42.2,110.2,45.4,113.3,49.2,113.3z"
                                />
                            </svg>
                            <label>diag</label>
                        </button>
                        <button className={`btn ${pathname == '/suggestions' ? 'active' : ''}`} onClick={() => navigate("/suggestions")}>
                            <svg className="icon" viewBox="0 0 200 200">
                                <path
                                    d="M122,184.6H78c-2.5,0-4.5-2-4.5-4.5v-0.4c0-2.5,2-4.5,4.5-4.5h44c2.5,0,4.5,2,4.5,4.5v0.4
                                        C126.5,182.6,124.5,184.6,122,184.6z"
                                />
                                <path
                                    d="M129.3,165.4H70.7c-2.5,0-4.6-2.1-4.6-4.6v-0.2c0-2.5,2.1-4.6,4.6-4.6h58.7c2.5,0,4.6,2.1,4.6,4.6v0.2
                                        C134,163.3,131.9,165.4,129.3,165.4z"
                                />
                                <path
                                    d="M114.1,200H85.9c-2,0-3.7-1.7-3.7-3.7v-2c0-2,1.7-3.7,3.7-3.7h28.1c2,0,3.7,1.7,3.7,3.7v2
                                        C117.7,198.3,116.1,200,114.1,200z"
                                />

                                <path
                                    d="M51.3,117.1c0,0,9.2,10.1,12.4,19.8c0.9,2.7,2.8,5,5.3,6.2c3.3,1.6,10.5,3.6,19.8,3.9l0,0c0.2,0,0.4,0,0.7,0
                                            c0.2,0,0.5,0,0.7,0c21,0.4,33.7-1.6,38.6-4c2.5-1.2,4.4-3.5,5.3-6.2c1.4-4.3,4.1-8.7,6.5-12.3c16.2-12.7,26.6-32.7,26.6-55.3
                                            C167.2,31.1,137.1,0,100,0S32.8,31.1,32.8,69.4C32.8,87.9,39.9,104.6,51.3,117.1L51.3,117.1z M49.1,62.4c0.3-0.5,0.5-1.1,0.6-1.7
                                            c1-7,2.8-12.8,5-17.6c11.5-22.4,34.4-22.2,34.7-22.2c2.4,0,4,1.2,4,3.7c0,2.5-2,4.6-4.4,4.6c-1.1,0-26.5,0.7-31.1,33.6
                                            c-0.3,2.3-2.2,3.9-4.4,3.9c-0.2,0-0.4,0-0.6,0C50.7,66.3,49.2,64.5,49.1,62.4z"
                                />
                            </svg>
                            <label>recom</label>
                        </button>

                        <button className={`btn ${pathname == '/search' ? 'active' : ''}`} onClick={() => navigate("/search")}>
                            <svg className="icon" viewBox="0 0 200 200">
                                <path
                                    d="M101.8,0C47.7-0.4,1.9,44.7,1.5,98.7c-0.2,24.8,8.7,47.5,23.5,65l-0.1-0.1l-12.8,28.7c-1.7,3.9,2,7.9,6,6.6
                                        l35.4-12l0,0c14.5,7.9,31.3,12.3,49.1,12c54.4-0.8,96.3-49.5,95.9-103.8C198.1,40.8,156.2,0.4,101.8,0z M99.8,145.1
                                        c-6.2,0-11.1-4.7-11.1-10.8s4.9-10.9,11.1-10.9c6,0,10.8,4.9,10.8,10.9S105.9,145.1,99.8,145.1z M116.6,92.7
                                        c-7.8,6.9-10.1,10.2-10.1,14.4l-0.1,1.4c-0.1,3.7-3.2,6.5-7,6.5c-3.9,0-7.2-3-7.2-6.9v-1.7c0-6,2.6-10.3,11.1-18.2
                                        c8.5-7.6,10.3-10.5,10.3-15.5c0-6.6-5-11.5-11.5-11.5c-5.3,0-9.6,2.9-12.4,8.5c-1.7,3-3.7,4.3-6.5,4.3c-3.7,0-6.8-3-6.8-6.8
                                        c0-1.7,0.4-3,1.3-4.6c4.5-9.8,13.4-15.4,24.9-15.4c15.1,0,26.6,10.2,26.6,23.9C129.3,78.6,125.7,84.6,116.6,92.7z"
                                />
                            </svg>
                            <label>faq</label>
                        </button>
                        {/* <button className={`btn ${pathname == '/guia' ? 'active' : ''}`} onClick={() => navigate("/guia")}>
                            <svg className="icon" viewBox="0 0 200 200">
                                <path
                                    d="M89.7,38.3L4.6,3.9C2.4,3,0,4.8,0,7.3v146.4c0,1.5,0.8,2.8,2.1,3.4l85.1,37.5c2.2,1,4.7-0.8,4.7-3.4V41.7
                                            C92,40.2,91.1,38.8,89.7,38.3z M28,58.8c1-2.2,3.6-3.2,5.9-2.2l33.6,15.1c2.2,1,3.2,3.6,2.2,5.9c-0.7,1.7-2.4,2.6-4.1,2.6
                                            c-0.6,0-1.2-0.1-1.8-0.4L30.2,64.7C28,63.7,27,61.1,28,58.8z M69.7,144.9c-0.7,1.6-2.4,2.6-4.1,2.6c-0.6,0-1.2-0.1-1.8-0.4
                                            l-33.6-15.4c-2.2-1-3.2-3.7-2.2-5.9c1-2.2,3.7-3.2,5.9-2.2L67.5,139C69.7,140,70.7,142.7,69.7,144.9z M71.8,110.8
                                            c-0.7,1.7-2.4,2.6-4.1,2.6c-0.6,0-1.2-0.1-1.8-0.4l-37-16.5c-2.2-1-3.3-3.6-2.3-5.9c1-2.2,3.6-3.3,5.9-2.3l37,16.5
                                            C71.8,105.9,72.8,108.5,71.8,110.8z"
                                />
                                <path
                                    d="M195.2,5.4L107,39.7c-1.4,0.5-2.3,1.9-2.3,3.5v149.5c0,2.6,2.5,4.4,4.9,3.4l88.2-37.5c1.3-0.6,2.2-1.9,2.2-3.4
                                            V8.8C200,6.2,197.5,4.4,195.2,5.4z M130.2,73.1L165,58.1c2.3-1,4.9,0.1,5.9,2.3c1,2.3-0.1,4.9-2.3,5.9l-34.8,15.1
                                            c-0.6,0.3-1.2,0.4-1.8,0.4c-1.7,0-3.4-1-4.1-2.7C126.9,76.7,127.9,74.1,130.2,73.1z M168.6,133.2l-34.8,15.4
                                            c-0.6,0.3-1.2,0.4-1.8,0.4c-1.7,0-3.3-1-4.1-2.7c-1-2.2,0-4.9,2.3-5.9l34.8-15.4c2.2-1,4.9,0,5.9,2.3
                                            C171.9,129.6,170.8,132.3,168.6,133.2z M169.9,98.1l-38.3,16.5c-0.6,0.2-1.2,0.4-1.8,0.4c-1.7,0-3.4-1-4.1-2.7
                                            c-1-2.3,0.1-4.9,2.3-5.8l38.3-16.5c2.3-1,4.9,0.1,5.8,2.3C173.2,94.5,172.1,97.1,169.9,98.1z"
                                />
                            </svg>
                            <p>Guías</p>
                        </button> */}
                    </div>
                </div>
            </footer>
        </>
    );
}