// React
import React, { useEffect, useState } from 'react';

// Icon
import { AiOutlineEye } from "react-icons/ai";

// Imagenes
import Logo from '../../assets/img/logo-horizontal.svg';

// Router
import { useNavigate } from 'react-router-dom';

// Auth
import { useAuth } from '../../contexts/auth';

// Mui
import { CircularProgress } from '@mui/material';

export default function Login() {

    useEffect(() => {
        if (document.getElementById('chat24-root') != undefined) {document.getElementById('chat24-root').classList.add('d-none')}
    }, [])
    

    
    const [isPassword, setIsPassword] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    const auth = useAuth();
    const navigate = useNavigate();


    async function formSubmit(e) {
        e.preventDefault();
        setIsLoading(true);

        const { email, password } = e.target.elements;
        const isLogin = await auth.login( email.value, password.value );

        if (isLogin) {
            navigate('/admin');
        }
        setIsLoading(false);

    }

    return (
        <div className='container-auth'>
            <div className='box-auth'>

                <img src={Logo} width='180px' className='mb-4 d-block mx-auto' alt="Logo" />

                <h2 className='mb-4'>Login admin</h2>

                <form onSubmit={formSubmit} id='form-login'>
                    <label htmlFor="" className='mb-2 d-block'>Usuario</label>
                    <input className='form-input' type='text' placeholder='Usuario' name='email'/>

                    <label htmlFor="" className='mb-2 d-block'>Contraseña</label>
                    <div className='form-input-pasword'>
                        <input type={ isPassword ? 'password' : 'text' } placeholder='Contraseña' name='password'/>
                        <AiOutlineEye size={'2rem'} className={ isPassword ? '' : 'active-eye' } onClick={ () => setIsPassword(!isPassword) } />
                    </div>
                    <button type='submit' className='btn btn-success btn-lg'> { isLoading ? <CircularProgress size='1.25rem' /> : 'Ingresar' } </button>
                </form>
            </div>
        </div>
    );
}